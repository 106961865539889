import {useEffect, useState} from 'react';

import {TextField, MenuItem, Box, Grid, DialogContent, useTheme, Typography} from '@mui/material';

import {CONNECTORS, CONNECTORS_HAVING_UPLOAD_FEATURE, DEFAULT_PROJECTS_SCHEMAS, SNACKBAR_ACTIONS} from '../../const';
import useAuth from '../../hooks/providers/useAuth';
import useProjects from '../../hooks/providers/useProjects';
import useReport from '../../hooks/providers/useReport';
import useSnackbar from '../../hooks/providers/useSnackbar';
import useUpload from '../../hooks/providers/useUpload';
import {getManualConnectorFilename, getHumanReadableSiren, getTruncatedString} from '../../utils';
import Alert from '../alert/Alert';
import OpenContactFormButton from '../contact/OpenContactFormButton/OpenContactFormButton';
import Button from '../form/buttons/Button/Button';
import ErrorMessage from '../form/ErrorMessage/ErrorMessage';
import BaseModal from '../modal/BaseModal';
import Spinner from '../spinner/Spinner';
import UploadDropzone from './UploadDropzone';

const sortProjects = projects => {
  const manualProjects = projects.filter(p => p.connector?.toUpperCase() === CONNECTORS.manual);
  const otherProjects = projects.filter(p => p.connector?.toUpperCase() !== CONNECTORS.manual);
  return [...manualProjects, ...otherProjects];
};

// eslint-disable-next-line complexity
const UploadDialog = () => {
  const {isUploadFilesModalOpen: isOpen, setIsUploadFilesModalOpen: setIsOpen} = useUpload();
  const projectsContext = useProjects();
  const {selectedReport} = useReport();
  const {projectSelected, setProjectSelected, isUploadFilesModalOpen} = useUpload();
  const auth = useAuth();
  const {showSnackbar, closeSnackbar} = useSnackbar();
  const theme = useTheme();

  const [files, setFiles] = useState(null);
  const [sortedProjects, setSortedProjects] = useState([]);

  const closeModal = () => {
    setIsOpen(false);
    projectsContext.setSelectedProject(null);
  };

  const resetState = () => {
    setFiles(null);
    closeModal();
    setProjectSelected('');
  };

  const handleSubmit = async () => {
    const snackbarId = showSnackbar(SNACKBAR_ACTIONS.IMPORT_PROJECT_FILES_IN_PROGRESS, {
      severity: 'info',
      autoHide: false
    });
    closeModal();
    const uploadPromisesResults = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const file of files) {
      // eslint-disable-next-line no-await-in-loop
      const res = await projectsContext.importFecFile(projectSelected, file);
      uploadPromisesResults.push(res);
    }

    setTimeout(() => {
      const allFilesUploadedSuccessfully = uploadPromisesResults.every(res => res.status === 200);
      closeSnackbar(snackbarId);
      if (allFilesUploadedSuccessfully) {
        showSnackbar(SNACKBAR_ACTIONS.IMPORT_PROJECT_FILES_SUCCESS);
        if (selectedReport) {
          selectedReport?.refresh();
        }
      } else {
        showSnackbar(SNACKBAR_ACTIONS.IMPORT_PROJECT_FILES_ERROR, {severity: 'error'});
      }
    }, 2500);
  };

  useEffect(() => {
    (async () => {
      setProjectSelected('');
      setFiles(null);
      if (auth?.user?.tokenAad && isOpen && projectsContext.projects?.length === 0) {
        await projectsContext.fetchProjects();
      }
    })();
  }, [isOpen]);

  useEffect(() => {
    setSortedProjects(sortProjects(projectsContext?.projects || []));
  }, [projectsContext.projects]);

  useEffect(() => {
    setProjectSelected(projectSelected || '');
  }, [isUploadFilesModalOpen]);

  useEffect(() => {
    files?.forEach(async file => {
      try {
        await getManualConnectorFilename(file, projectSelected);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    });
  }, [files]);

  const currentUserProjects = sortedProjects?.filter(p => p.is_owner && p.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion);

  return (
    <BaseModal onClose={resetState} open={isOpen} title="Importez vos fichiers">
      <DialogContent>
        <Spinner text="Chargement des connexions" isLoading={projectsContext.isLoading} />
        {!projectsContext.isLoading && (
          <Box mt={1} mb={2}>
            <TextField
              onChange={e => setProjectSelected(e.target.value)}
              fullWidth
              id="outlined-select-project"
              select
              label="Choisissez une connexion ..."
              value={projectSelected || ''}
              helperText={
                <Typography fontSize={13} mt={0.5}>
                  Glisser-déposer un ou plusieurs fichiers FEC.{' '}
                  <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000027804775" target="_blank" rel="noreferrer">
                    En savoir plus sur le format FEC
                  </a>
                </Typography>
              }
            >
              {currentUserProjects.length === 0 && (
                <MenuItem disabled value={null}>
                  Pas de connexion
                </MenuItem>
              )}
              {currentUserProjects.map(project => {
                const isProjectManual = CONNECTORS_HAVING_UPLOAD_FEATURE.includes(project.connector);
                return (
                  <MenuItem disabled={!isProjectManual} key={project.siren} value={project.siren}>
                    {getTruncatedString(project.project_name)} ({getHumanReadableSiren(project.siren)}) {!isProjectManual && ' - Mise à jour automatique'}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
        )}

        {projectSelected !== '' && !projectsContext.isLoading && (
          <Box pt={2} mb={2}>
            <UploadDropzone error="" onFilesUpload={setFiles} />
          </Box>
        )}

        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={5}>
            <Button disabled={!projectSelected || !files || files?.length === 0} onClick={handleSubmit} fullWidth variant="contained" color="secondary" size="large">
              Importer vos données
            </Button>
          </Grid>
          {projectsContext?.importFecFileError !== '' && <ErrorMessage message={projectsContext?.importFecFileError} />}
        </Grid>
      </DialogContent>
      <Alert
        sx={{
          marginTop: theme.spacing(2),
          background: theme.palette.primary.light,
          color: theme.typography.color.main
        }}
      >
        <Typography fontSize={14}>Pour toute question,</Typography>
        <OpenContactFormButton text="Contactez-nous !" />
      </Alert>
    </BaseModal>
  );
};

export default UploadDialog;
