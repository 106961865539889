import Bowser from 'bowser';

import {BREAKPOINTS} from '../const';

const isMobile = () => {
  const parser = Bowser.getParser(navigator.userAgent);
  return parser.getPlatformType() === 'mobile';
};

const isResponsive = currentBreakpoint => {
  const isSmallScreen = currentBreakpoint === BREAKPOINTS.xs || currentBreakpoint === BREAKPOINTS.sm;
  return isMobile() || isSmallScreen;
};

const capitalizeWord = word => {
  const firstLetterCap = word.charAt(0).toUpperCase();
  const remainingLetters = word.slice(1);
  const capitalizedWord = firstLetterCap + remainingLetters;

  return capitalizedWord;
};

const wait = async delayInMilliseconds => {
  // eslint-disable-next-line no-promise-executor-return
  await new Promise(resolve => setTimeout(resolve, delayInMilliseconds));
};

const sortProjectsAlphabetically = (projects = []) => projects.sort((a, b) => a.project_name.toLowerCase().localeCompare(b.project_name.toLowerCase()));
const sortProjectsAlphabeticallyByConnectorName = (projects = []) => projects.sort((a, b) => a.connector.toLowerCase().localeCompare(b.connector.toLowerCase()));

export {isMobile, wait, isResponsive, capitalizeWord, sortProjectsAlphabetically, sortProjectsAlphabeticallyByConnectorName};
