import useProjects from '../../providers/useProjects';

export function useConnectionFiltering() {
  const {searchedConnectionTerm: searchTerm} = useProjects();

  const getFilteredConnections = connections => {
    if (!searchTerm) return connections;

    const lowerCaseSearchedProject = searchTerm.toLowerCase();
    const filtered = connections.filter(
      p =>
        p.project_name.toLowerCase().includes(lowerCaseSearchedProject) ||
        p.siren.includes(lowerCaseSearchedProject) ||
        p.users?.some(u => u.username.toLowerCase().includes(lowerCaseSearchedProject)) ||
        p.connector.includes(lowerCaseSearchedProject)
    );
    return filtered;
  };

  return {getFilteredConnections};
}
