import {useEffect} from 'react';

import {Box, Grid} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';

import PageContainer from '../components/layout/PageContainer';
import ExpiredSubscriptionAlert from '../components/project/alerts/ExpiredSubscriptionAlert';
import ExpiredTrialAlert from '../components/project/alerts/ExpiredTrialAlert';
import InsufficientSubscriptionAlert from '../components/project/alerts/InsufficientSubscriptionAlert';
import ConnectionsGrid from '../components/project/ConnectionsGrid';
import ConnectionsList from '../components/project/ConnectionsList';
import ConnectionsSections from '../components/project/ConnectionsSections';
import DeleteAllConnectionsButton from '../components/project/DeleteAllConnectionsButton/DeleteAllConnectionsButton';
import ConnectionsPageModals from '../components/project/modals/ConnectionsPageModals';
import NoConnectionsFound from '../components/project/NoConnectionsFound';
import SeeCustomReportButton from '../components/project/SeeCustomReportButton';
import ActualizeDataDatePicker from '../components/project/single-project-item/actualize-data-button/ActualizeDataDatePicker';
import ConnectionsToolbar from '../components/project/toolbar/ConnectionsToolbar/ConnectionsToolbar';
import Spinner from '../components/spinner/Spinner';
import ReportTabs from '../components/tabs/ReportTabs';
import WorkspacesTabs from '../components/tabs/WorkspacesTabs';
import {BREAKPOINTS, PROJECTS_VIEW_TYPES} from '../const';
import {useConnectionFiltering} from '../hooks/components/connections/useConnectionsFiltering';
import useFetchConnectionsAndWorkspaces from '../hooks/components/connections/useFetchConnectionsAndWorkspaces';
import useFetchProductsList from '../hooks/components/connections/useFetchProductsList';
import useOpenConnectionCreationModalAfterTiimeRedirection from '../hooks/components/connections/useOpenConnectionCreationModalAfterTiimeRedirection';
import useBreakpoint from '../hooks/dom/useBreakpoint';
import useProjects from '../hooks/providers/useProjects';
import useWorkspaces from '../hooks/providers/useWorkspaces';

const ContentContainer = muiStyled(Box)(({theme}) => ({
  // maxWidth: '100%',
  width: '100%',
  [theme.breakpoints.up('xs')]: {
    padding: '0 10px',
    margin: '0 6px'
  },
  [theme.breakpoints.up('sm')]: {
    padding: '0 24px',
    margin: '0 12px'
  },
  [theme.breakpoints.up('md')]: {
    padding: '0 32px',
    margin: '0 16px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 48px',
    margin: '0 16px'
  }
}));

const Connections = () => {
  const {projects, isLoading, currentReport, viewType, setViewType, createProjectModalOpen, searchedConnectionTerm: searchedProject, setSearchedConnectionTerm: setSearchedProject} = useProjects();
  const {isDefaultWorkspace} = useWorkspaces();
  const breakpoint = useBreakpoint();

  useFetchConnectionsAndWorkspaces();
  useFetchProductsList();
  useOpenConnectionCreationModalAfterTiimeRedirection();
  const {getFilteredConnections} = useConnectionFiltering();

  // This hook tracks window size. Its goal is to automatically set projects list view type to CARD
  // When screen becomes too small to display projects as a list
  useEffect(() => {
    if (breakpoint === BREAKPOINTS.xs && viewType !== PROJECTS_VIEW_TYPES.card) {
      setViewType(PROJECTS_VIEW_TYPES.card);
    }
  }, [breakpoint, viewType]);

  const renderCustomWorkspaceConnections = () => {
    const filteredConnections = getFilteredConnections(projects);

    return viewType === PROJECTS_VIEW_TYPES.card ? <ConnectionsGrid connections={filteredConnections} /> : <ConnectionsList connections={filteredConnections} />;
  };

  const renderCustomOrNoticeReportContent = () => {
    const {is_notice_report: isNoticeReport, isCustomModel} = currentReport;
    if (!isNoticeReport && !isCustomModel) return null;

    return (
      <Grid item xs="auto" sx={{mt: 2}}>
        <SeeCustomReportButton isNoticeReport={isNoticeReport} isCustomReport={isCustomModel} />
      </Grid>
    );
  };

  const renderConnectionsContent = () => {
    const filteredConnections = getFilteredConnections(projects);
    const noProjectsFound = filteredConnections.length === 0 && !isLoading && searchedProject;

    return (
      <>
        <ExpiredTrialAlert />
        <ExpiredSubscriptionAlert />
        <InsufficientSubscriptionAlert />

        <ConnectionsToolbar />
        <Spinner text="Chargement des connexions" isLoading={isLoading && !createProjectModalOpen} />
        <ActualizeDataDatePicker />
        <DeleteAllConnectionsButton />

        {isDefaultWorkspace ? <ConnectionsSections /> : renderCustomWorkspaceConnections()}

        {noProjectsFound && <NoConnectionsFound />}
      </>
    );
  };

  const Container = isDefaultWorkspace ? Box : ReportTabs;

  return (
    <PageContainer>
      <WorkspacesTabs />
      <ContentContainer>
        <Container>
          <Grid container flex={1} maxWidth="lg" sx={{margin: '0 auto'}}>
            <Grid item xs={12} pb={3} px={{xs: 0, sm: 2}}>
              {renderCustomOrNoticeReportContent() || renderConnectionsContent()}
            </Grid>
          </Grid>
        </Container>
        <ConnectionsPageModals />
      </ContentContainer>
    </PageContainer>
  );
};

export default Connections;
