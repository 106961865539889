import {bool, string} from 'prop-types';
import {ReactSVG} from 'react-svg';

import {LOGO_THEME_VARIANTS} from '../const';

const getFileName = (variant, isSymbol) => {
  let filename;

  if (!isSymbol) {
    filename = `${process.env.REACT_APP_COMPANY_NAME}-logo.svg`;
  } else {
    filename = variant === LOGO_THEME_VARIANTS.dark ? process.env.REACT_APP_COMPANY_SYMBOL_FILENAME_DARK : process.env.REACT_APP_COMPANY_SYMBOL_FILENAME;
  }
  return filename;
};

const getFile = (variant, isSymbol) => {
  const filename = getFileName(variant, isSymbol);
  const filepath = `./${filename}`;

  try {
    const file = require(`./${filename}`); // eslint-disable-line global-require
    return file;
  } catch (e) {
    const typeOfLogo = isSymbol ? 'symbol' : 'logo';
    // eslint-disable-next-line no-console
    console.error(`Could not find file ${filepath} as company ${typeOfLogo} file in company-logo.js`);
    return '';
  }
};

const Logo = ({variant, isSymbol, ...props}) => {
  const file = getFile(variant, isSymbol);

  if (!isSymbol) {
    return <ReactSVG src={file.toString()} className="logo" wrapper="span" />;
  }

  if (variant === LOGO_THEME_VARIANTS.dark) {
    return <img {...props} alt={`${process.env.REACT_APP_COMPANY_NAME}`} src={file} />;
  }

  return <ReactSVG src={file.toString()} />;
};

Logo.defaultProps = {
  variant: LOGO_THEME_VARIANTS.light,
  isSymbol: false
};

Logo.propTypes = {
  variant: string,
  isSymbol: bool
};

export default Logo;
