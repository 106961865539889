import {useEffect} from 'react';

import {useWindowSize} from '@uidotdev/usehooks';

const useReportContainerAutoResize = () => {
  const windowSize = useWindowSize();

  // This hook aims to constantly resize iframe container. This is kind of a hack to allow report to take full width
  // after performing a report.refresh(). Without this hook, there is a small lag and PBI wrongly calculates available width, causing report to be smaller than before refresh
  useEffect(() => {
    const interval = setInterval(() => {
      const iframe = document.querySelector('.report-embed');

      if (iframe) {
        const availableWidth = windowSize.width;
        const availableHeight = windowSize.height;
        iframe.style.width = `${availableWidth}px`;
        iframe.style.height = `${availableHeight - 64}px`;
      }
    }, 50);

    return () => clearInterval(interval);
  }, [windowSize]);
};

export default useReportContainerAutoResize;
