import {Box, Typography} from '@mui/material';
import {number} from 'prop-types';

import ExportProgressBar from '../ExportProgressBar/ExportProgressBar';

const ProgressBarSection = ({numberOfPagesExported, totalNumberOfPages, numberOfVisualsExported, totalNumberOfVisualsInPage}) => (
  <>
    <Box mt={4}>
      <Typography fontFamily="InstrumentSansBold" fontSize={13} textTransform="uppercase" color="text.secondary">
        Pages du rapport exportées
      </Typography>
      <ExportProgressBar value={numberOfPagesExported} maxValue={totalNumberOfPages} />
    </Box>

    <Box mt={2}>
      <Typography fontFamily="InstrumentSansBold" fontSize={13} textTransform="uppercase" color="text.secondary">
        Visuels exportés sur la page
      </Typography>
      <ExportProgressBar value={numberOfVisualsExported} maxValue={totalNumberOfVisualsInPage} />
    </Box>
  </>
);

ProgressBarSection.defaultProps = {
  numberOfPagesExported: 0,
  totalNumberOfPages: 0,
  numberOfVisualsExported: 0,
  totalNumberOfVisualsInPage: 0
};

ProgressBarSection.propTypes = {
  numberOfPagesExported: number,
  totalNumberOfPages: number,
  numberOfVisualsExported: number,
  totalNumberOfVisualsInPage: number
};

export default ProgressBarSection;
