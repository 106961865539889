const ACTIONS_TYPES = {
  SET_CONNECTIONS: 'setConnections',
  UPDATE_CONNECTION: 'updateConnection',
  UPDATE_CONNECTION_SUCCESS: 'updateConnectionSuccess',
  UPDATE_CONNECTION_FAIL: 'updateConnectionFail',
  DELETE_CONNECTION: 'deleteConnection',
  DELETE_CONNECTION_SUCCESS: 'deleteConnectionSuccess',
  DELETE_CONNECTION_FAIL: 'deleteConnectionFail',
  SET_NEW_CONNECTOR_DATA_INTEGRATION_JOB_URL: 'setNewConnectorDataIntegrationJobUrl'
};

const ActionCreators = dispatch => ({
  setConnections: connections => {
    dispatch({type: ACTIONS_TYPES.SET_CONNECTIONS, payload: connections});
  },
  updateConnection: conn => {
    dispatch({type: ACTIONS_TYPES.UPDATE_CONNECTION, payload: conn});
  },
  updateConnectionSuccess: conn => {
    dispatch({type: ACTIONS_TYPES.UPDATE_CONNECTION_SUCCESS, payload: conn});
  },
  updateConnectionFail: conn => {
    dispatch({type: ACTIONS_TYPES.UPDATE_CONNECTION_FAIL, payload: conn});
  },
  deleteConnection: conn => {
    dispatch({type: ACTIONS_TYPES.DELETE_CONNECTION, payload: conn});
  },
  deleteConnectionSuccess: conn => {
    dispatch({type: ACTIONS_TYPES.DELETE_CONNECTION_SUCCESS, payload: conn});
  },
  deleteConnectionFail: conn => {
    dispatch({type: ACTIONS_TYPES.DELETE_CONNECTION_FAIL, payload: conn});
  },
  setDataIntegrationJobUrl: url => {
    dispatch({type: ACTIONS_TYPES.SET_NEW_CONNECTOR_DATA_INTEGRATION_JOB_URL, payload: url});
  }
});

const initialState = {
  newConnectionDataIntegrationJobUrl: null,
  projects: [],
  deleting: [],
  updating: []
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.SET_CONNECTIONS:
      return {...state, projects: action.payload};

    case ACTIONS_TYPES.UPDATE_CONNECTION:
      return {
        ...state,
        updating: [...state.updating, action.payload.siren + action.payload.schema_name]
      };

    case ACTIONS_TYPES.UPDATE_CONNECTION_SUCCESS:
      return {
        ...state,
        projects: state.projects.map(project => (project.siren === action.payload.siren && project.schema_name === action.payload.schema_name ? {...project, ...action.payload} : project)),
        updating: state.updating.filter(id => id !== action.payload.siren + action.payload.schema_name)
      };

    case ACTIONS_TYPES.UPDATE_CONNECTION_FAIL:
      return {
        ...state,
        updating: state.updating.filter(id => id !== action.payload.siren + action.payload.schema_name)
      };

    case ACTIONS_TYPES.DELETE_CONNECTION:
      return {
        ...state,
        deleting: [...state.deleting, action.payload.siren + action.payload.schema_name]
      };

    case ACTIONS_TYPES.DELETE_CONNECTION_SUCCESS:
      return {
        ...state,
        projects: state.projects.filter(project => project.siren !== action.payload.siren || project.schema_name !== action.payload.schema_name),
        deleting: state.deleting.filter(id => id !== action.payload.siren + action.payload.schema_name)
      };

    case ACTIONS_TYPES.DELETE_CONNECTION_FAIL:
      return {
        ...state,
        deleting: state.deleting.filter(id => id !== action.payload.siren + action.payload.schema_name)
      };

    case ACTIONS_TYPES.SET_NEW_CONNECTOR_DATA_INTEGRATION_JOB_URL:
      return {...state, newConnectionDataIntegrationJobUrl: action.payload};

    default:
      throw new Error(`Action type ${action.type} doesn't exist`);
  }
};

export {reducer, initialState, ActionCreators};
