import jwtDecode from 'jwt-decode';

import {SNACKBAR_ACTIONS} from '../../const';
import {isTokenExpired} from '../../utils';
import useAuth from '../providers/useAuth';
import useLoading from '../providers/useLoading';
import useSnackbar from '../providers/useSnackbar';

const HTTP_METHODS = {
  POST: 'POST',
  GET: 'GET',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
  PUT: 'PUT'
};

const useHttp = () => {
  const auth = useAuth();
  const snackbarContext = useSnackbar();
  const {setGlobalLoading} = useLoading();

  // eslint-disable-next-line complexity,consistent-return
  const createHttpRequest = async (url, options = {}) => {
    const {method, headers, body, shouldDisplayErrorSnackbar = true} = options;
    const methodsThatAcceptBody = [HTTP_METHODS.POST, HTTP_METHODS.PATCH, HTTP_METHODS.PUT];

    if (auth?.isRegeneratingToken) {
      // eslint-disable-next-line no-console
      console.warn('Prevent API calls, currently regenerating token');
      return false;
    }

    if (auth.loggedIntoBadPartnerErrorMessage) {
      auth.setLoggedIntoBadPartnerErrorMessage(null);
      auth.setLoggedIntoBadPartnerRedirectUrl(null);
    }

    if (auth?.user?.tokenAad) {
      const decodedToken = jwtDecode(auth.user.tokenAad);
      const tokenExpired = isTokenExpired(decodedToken);
      if (tokenExpired) {
        auth.setIsTokenInvalid(true);

        const response = {
          status: 401,
          ok: false
        };
        return {response};
      }
    }

    const response = await fetch(url, {
      method,
      headers: {
        ...(auth?.user?.tokenAad ? {Authorization: `Bearer ${auth.user.tokenAad}`} : {}),
        'Content-Type': 'application/json',
        policy: process.env.REACT_APP_COMPANY_POLICY,
        ...headers
      },
      ...(methodsThatAcceptBody.includes(method) && {body: JSON.stringify(body)})
    });

    // This should handle all 5XX HTTP codes
    if (response.status >= 500 && shouldDisplayErrorSnackbar) {
      setGlobalLoading(false);
      snackbarContext.showSnackbar(SNACKBAR_ACTIONS.TECHNICAL_ERROR, {severity: 'error', duration: 5000});
    }

    const contentType = response.headers?.get('Content-Type')?.toLowerCase();

    if (!contentType) {
      return {response};
    }

    if (contentType.includes('application/json')) {
      let responseJson = null;
      try {
        responseJson = await response.json();
        // API returns 401 and code BadPartner if user is logged into the wrong environment (EG: account created on Drivn but logged into Zenfirst)
        if (response.status === 401 && responseJson.code === 'BadPartner') {
          auth.setLoggedIntoBadPartnerErrorMessage(responseJson.message);
          auth.setLoggedIntoBadPartnerRedirectUrl(responseJson.redirecturl);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      return {response, responseJson};
    }

    if (contentType.includes('text')) {
      const responseText = await response.text();
      return {response, responseJson: responseText};
    }

    const fileTypes = ['application/octet-stream', 'application/pdf', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
    if (fileTypes.includes(contentType)) {
      const responseText = await response.arrayBuffer();
      return {response, responseJson: responseText};
    }
  };

  const _post = async (url, body, options = {}) =>
    createHttpRequest(url, {
      body,
      method: HTTP_METHODS.POST,
      ...options
    });

  const _get = async (url, headers = {}) =>
    createHttpRequest(url, {
      headers,
      method: HTTP_METHODS.GET
    });

  const _delete = async (url, headers = {}) =>
    createHttpRequest(url, {
      headers,
      method: HTTP_METHODS.DELETE
    });

  const _patch = async (url, body, headers = {}) =>
    createHttpRequest(url, {
      headers,
      body,
      method: HTTP_METHODS.PATCH
    });

  const _put = async (url, body, headers = {}) =>
    createHttpRequest(url, {
      headers,
      body,
      method: HTTP_METHODS.PUT
    });

  return {
    _get,
    _post,
    _put,
    _patch,
    _delete
  };
};

export default useHttp;
