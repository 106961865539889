import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import useProfile from '../../../hooks/providers/useProfile';
import useReport from '../../../hooks/providers/useReport';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';
import EditableReportSaveAsModal from '../EditableReportSaveAsModal';
import EditReportButton from './EditReportButton';
import SaveReportButton from './SaveReportButton';

const EditableReportButtons = () => {
  const {isReportEditable, isDesktopLayout, selectedReport} = useReport();
  const {workspaces, isUserInWorkspace} = useWorkspaces();
  const {profile} = useProfile();

  const reportWorkspace = workspaces.find(w => (w.reports || []).find(r => r.report_id === selectedReport.config.id) !== undefined);
  const userIsInWorkspace = isUserInWorkspace(profile.id, reportWorkspace);

  const shouldDisplayButton = isReportEditable && isDesktopLayout && userIsInWorkspace;

  if (!shouldDisplayButton) return null;

  return (
    <Box sx={{position: 'fixed', bottom: 6}}>
      <Grid container justifyContent="center" spacing={1}>
        <Grid item>
          <EditReportButton />
        </Grid>
        <Grid item>
          <SaveReportButton />
        </Grid>
      </Grid>
      <EditableReportSaveAsModal />
    </Box>
  );
};

export default EditableReportButtons;
