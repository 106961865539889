/* eslint-disable no-await-in-loop */
import {useReducer, useRef} from 'react';

import {models} from 'powerbi-client';

import {reportExportReducer, initialState, ACTIONS_TYPES, REPORT_EXPORTING_STEPS} from 'reducers/components/report-export/reportExportReducer';
import {wait, downloadFileFromApiRawResult} from 'utils';

import {EXPORTED_REPORT_FILE_TYPES, POWER_BI_VISUALS_SELECTORS_NEEDED_FOR_EXPORT, SNACKBAR_ACTIONS} from '../../../const';
import useReport from '../../providers/useReport';
import useSnackbar from '../../providers/useSnackbar';

const useReportExporter = () => {
  const [state, dispatch] = useReducer(reportExportReducer, initialState);
  const exportHasBeenCancelled = useRef(false);

  const {selectedReport, reports, exportReportDataToFile, allReportVisuals, setIsReportExportTypeChoiceModalOpen} = useReport();
  const {showSnackbar, closeSnackbar, defaultSnackbarOptions} = useSnackbar();

  const exportSinglePageVisuals = async pageVisuals => {
    const formattedPageVisuals = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const v of pageVisuals) {
      if (exportHasBeenCancelled.current) break;

      try {
        let singleVisualData = {};

        // TODO UNCOMMENT once adrian did his tests
        // if (!NON_EXPORTABLE_VISUAL_TYPES.includes(v.type)) {
        //   const visualData = await v.exportData(models.ExportDataType.Summarized);
        //   singleVisualData = visualData.data;
        // }

        try {
          // TODO COMMENT once adrian did his tests
          const visualData = await v.exportData(models.ExportDataType.Summarized);
          singleVisualData = visualData.data;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.info(e);
        }

        const capabilities = await v.getCapabilities();
        const {dataRoles} = capabilities;

        const dataFieldsPromises = (dataRoles || []).map(c => v.getDataFields(c.name));
        const dataFields = await Promise.all(dataFieldsPromises);

        dataFields.forEach((dataField, index) => {
          if (dataField.length > 0) {
            dataRoles[index].dataFields = dataField;
          }
        });

        const properties = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const selector of POWER_BI_VISUALS_SELECTORS_NEEDED_FOR_EXPORT) {
          try {
            const resProperty = await v.getProperty(selector);
            properties.push({selector, property: resProperty});
          } catch (e) {
            // We do not log "unsupportedProperty" error messages because we already know that sometimes we will get properties
            // Using inappropriate selector for the visual's type, and it will throw this error
            if (e.message !== 'unsupportedProperty') {
              // eslint-disable-next-line no-console
              console.info({visual: v, selector, error: e});
            }
          }
        }

        const {page, name, ...visual} = v;
        const formattedVisual = {
          layout: visual,
          data: singleVisualData,
          dataRoles,
          properties
        };

        formattedPageVisuals.push(formattedVisual);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
      dispatch({type: ACTIONS_TYPES.INCREMENT_NUMBER_OF_PAGE_VISUALS_EXPORTED});
    }

    return formattedPageVisuals;
  };

  const exportAllVisuals = async () => {
    dispatch({type: ACTIONS_TYPES.SET_EXPORTING_STEP, payload: REPORT_EXPORTING_STEPS.EXPORTING_VISUALS});
    const pageBeforeExporting = await selectedReport.getActivePage();
    const orderByPageAndFormattedVisuals = [];
    const distinctReportPages = [...new Set(allReportVisuals.map(v => v.page.displayName))];

    dispatch({type: ACTIONS_TYPES.SET_TOTAL_PAGES, payload: distinctReportPages.length});

    // eslint-disable-next-line no-restricted-syntax
    for (const reportPage of distinctReportPages) {
      if (exportHasBeenCancelled.current) break;
      await wait(500);
      const singlePageVisuals = allReportVisuals.filter(v => v.page.displayName === reportPage);
      dispatch({type: ACTIONS_TYPES.SET_TOTAL_VISUALS_IN_PAGE, payload: singlePageVisuals.length});
      dispatch({type: ACTIONS_TYPES.SET_NUMBER_OF_VISUALS_EXPORTED, payload: 0});

      await selectedReport.setPage(singlePageVisuals[0].page.name);
      const formattedPageVisuals = await exportSinglePageVisuals(singlePageVisuals);

      orderByPageAndFormattedVisuals.push({page_name: reportPage, visuals: formattedPageVisuals});
      dispatch({type: ACTIONS_TYPES.INCREMENT_NUMBER_OF_PAGES_EXPORTED});
    }

    await selectedReport.setPage(pageBeforeExporting.name);
    await wait(250);

    return orderByPageAndFormattedVisuals;
  };

  const exportAndDownloadFile = async (exportType, reportName, visuals = []) => {
    dispatch({type: ACTIONS_TYPES.SET_EXPORTING_STEP, payload: REPORT_EXPORTING_STEPS.GENERATING_FILE});

    let snackbarId;
    if (exportType !== EXPORTED_REPORT_FILE_TYPES.POWERPOINT_NATIVE) {
      snackbarId = showSnackbar(
        SNACKBAR_ACTIONS.EXPORT_REPORT_IN_PROGRESS,
        {
          severity: 'info',
          autoHide: false
        },
        {exportedReportName: reportName}
      );
    }
    const res = await exportReportDataToFile(reportName, visuals, exportType);
    closeSnackbar(snackbarId);

    if (res.success) {
      const extension = exportType === EXPORTED_REPORT_FILE_TYPES.PDF ? '.pdf' : '.pptx';
      downloadFileFromApiRawResult(res.data, `${reportName}${extension}`);
      showSnackbar(SNACKBAR_ACTIONS.EXPORT_REPORT_SUCCESS, defaultSnackbarOptions, {exportedReportName: reportName});
    }
  };

  const handleExport = async exportType => {
    dispatch({type: ACTIONS_TYPES.START_EXPORT});

    const reportName = reports?.find(r => r.report_id === selectedReport.config.id)?.report_name;
    if (exportType === EXPORTED_REPORT_FILE_TYPES.POWERPOINT_NATIVE) {
      dispatch({type: ACTIONS_TYPES.SET_PROGRESS_VISIBILITY, payload: true});
      exportHasBeenCancelled.current = false;
      const visuals = await exportAllVisuals();

      if (!exportHasBeenCancelled.current) {
        await exportAndDownloadFile(exportType, reportName, visuals);
      }
    } else {
      setIsReportExportTypeChoiceModalOpen(false);
      exportAndDownloadFile(exportType, reportName);
    }

    dispatch({type: ACTIONS_TYPES.STOP_EXPORT});
  };

  return {state, handleExport, cancelExport: () => (exportHasBeenCancelled.current = true)};
};

export default useReportExporter;
