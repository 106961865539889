import {useEffect, useState} from 'react';

import {MenuItem} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {APP_ROUTES} from '../../const';
import useReport from '../../hooks/providers/useReport';
import {makeHumanFriendlyUrl} from '../../utils';
import Select from '../form/MuiSelect/MuiSelect';

const SelectContainer = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  flex-basis: 250px;
  width: 100%;
  box-sizing: border-box;
  justify-self: flex-start;
`;
const HeaderReportsSelect = () => {
  const [reportOptions, setReportOptions] = useState([]);

  const {selectedReport, reports, setSelectedProject} = useReport();
  const navigate = useNavigate();

  const getReportValue = report => (report ? reportOptions.find(rep => rep.value === report.config.id) : null);

  useEffect(() => {
    setReportOptions(reports?.map(rep => ({value: rep.report_id, label: rep.report_name})) || []);
  }, [reports]);

  const navigateToReport = reportId => {
    setSelectedProject(null);

    const reportName = reports.find(r => r.report_id === reportId).report_name;
    const url = makeHumanFriendlyUrl(APP_ROUTES.report(reportName));
    navigate(url, {
      state: {
        reportId
      }
    });
  };

  return (
    <SelectContainer>
      <Select
        darkMode
        renderValue={() => {
          const reportValue = getReportValue(selectedReport);
          if (!reportValue) {
            return <em>Sélectionnez un rapport</em>;
          }
          return reportValue.label;
        }}
        withoutLabel
        variant="filled"
        sx={{
          '& .MuiInputBase-root': {
            backgroundColor: 'transparent !important',
            '&:hover': {
              backgroundColor: 'transparent !important'
            },
            '&.Mui-focused': {
              backgroundColor: 'transparent !important'
            }
          },
          width: '100%',
          '& .MuiInputBase-formControl': {
            color: 'white !important',
            background: 'transparent'
          },
          '& .MuiInputBase-formControl::before': {
            borderBottom: 'none !important'
          },
          '& .MuiSelect-iconFilled': {
            color: 'white !important'
          }
        }}
        disabled={reports.length === 0}
        items={reportOptions.map((opt, i) => (
          <MenuItem
            key={i}
            value={opt.value}
            component="a"
            href={`${makeHumanFriendlyUrl(APP_ROUTES.report(opt.label))}`}
            onContextMenu={() => {
              localStorage.setItem('reportId', opt.value);
            }}
            onClick={e => {
              e.preventDefault(); // Prevents full reload
            }}
          >
            {opt.label}
          </MenuItem>
        ))}
        value={getReportValue(selectedReport)?.value || ''}
        onChange={e => navigateToReport(e.target.value)}
      />
    </SelectContainer>
  );
};

export default HeaderReportsSelect;
