import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress, {linearProgressClasses} from '@mui/material/LinearProgress';
import {styled} from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 12,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main
  }
}));

/**
 * Normalises value to be used by MUI Progress component (range 0-100)
 * @param value
 * @param maxValue
 * @returns {number}
 */
const normaliseValue = (value, maxValue) => {
  const minValue = 0;
  if (value <= minValue) {
    return 0;
  }
  if (value >= maxValue) {
    return 100;
  }
  return ((value - minValue) / (maxValue - minValue)) * 100;
};

// eslint-disable-next-line react/prop-types
const ExportProgressBar = ({value, maxValue}) => {
  const progress = normaliseValue(value, maxValue);

  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <Box sx={{width: '100%', mr: 1, overflow: 'hidden', borderRadius: 5}}>
        <BorderLinearProgress variant="determinate" value={progress} />
      </Box>
      <Box sx={{minWidth: 35}}>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

export default ExportProgressBar;
