import {useState} from 'react';

import {DialogActions, DialogContent, MenuItem, TextField} from '@mui/material';

import {MONTHS, DEFAULT_PROJECTS_SCHEMAS, REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import Button from '../../form/buttons/Button/Button';
import BaseModal from '../../modal/BaseModal';

const UpdateProjectModal = () => {
  const {closeProjectMenu, updateProjectModalOpen: open, setUpdateProjectModalOpen: setOpen, updateProject, selectedProject: project} = useProjects();

  const [name, setName] = useState(project.project_name);
  const [month, setMonth] = useState(project.first_fiscal_month?.toString());
  const [logo, setLogo] = useState(project.url_logo);

  const onClose = () => {
    setOpen(false);
    closeProjectMenu();
  };

  const handleSubmit = async () => {
    const data = {
      siren: project.siren,
      logo,
      name,
      month,
      schema_name: project.schema_name
    };

    onClose();
    updateProject(data);
  };

  const hasNameError = name !== '' && !REGULAR_EXPRESSIONS.label.test(name);
  const getNameInputErrorText = () => {
    if (!name) return REGULAR_EXPRESSIONS_ERROR_TEXTS.mandatoryField;
    if (hasNameError) return REGULAR_EXPRESSIONS_ERROR_TEXTS.label;
    return '';
  };

  const hasLogoError = logo && !REGULAR_EXPRESSIONS.url.test(logo);

  const canSubmit = name && !hasLogoError && !hasNameError;

  return (
    <BaseModal onClose={onClose} open={open} title="Paramètres de la connexion">
      <DialogContent>
        <TextField size="small" disabled value={project.siren} label="Siren" color="primary" fullWidth />
        <TextField
          size="small"
          error={!name || hasNameError}
          helperText={getNameInputErrorText()}
          value={name}
          onChange={event => {
            setName(event.target.value);
          }}
          onKeyDown={e => e.stopPropagation()} // see : https://github.com/mui/material-ui/issues/19116
          label="Nom de la connexion"
          placeholder="Nom de la connexion ..."
          color="primary"
          fullWidth
          sx={{mt: 3}}
        />

        {project.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion ? (
          <TextField sx={{mt: 3}} size="small" onChange={e => setMonth(e.target.value)} fullWidth select label="Premier mois fiscal" value={month}>
            {MONTHS.map(m => (
              <MenuItem key={m.id} value={m.id}>
                {m.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        <TextField
          size="small"
          type="url"
          value={logo}
          error={hasLogoError}
          helperText={hasLogoError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.url : ''}
          onChange={event => {
            setLogo(event.target.value);
          }}
          onKeyDown={e => e.stopPropagation()} // see : https://github.com/mui/material-ui/issues/19116
          label="Url du logo"
          color="primary"
          fullWidth
          placeholder="https://..."
          sx={{mt: 3}}
        />
      </DialogContent>
      <DialogActions mt={0} sx={{flexDirection: 'column'}}>
        <Button size="large" disabled={!canSubmit} fullWidth variant="contained" color="secondary" onClick={handleSubmit}>
          Sauvegarder
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

export default UpdateProjectModal;
