import {RuleFolder} from '@mui/icons-material';

import useProjectCrudModal from '../../../../hooks/dom/useProjectCrudModal';
import useProjects from '../../../../hooks/providers/useProjects';
import {projectShape} from '../../../../proptypes-shapes';
import ProjectActionButton from '../ProjectActionButton';
import ProjectMenuUpdateButton from './ProjectMenuUpdateButton';

const UpdateProjectButton = ({project}) => {
  const {setUpdateProjectModalOpen, isProjectCurrentlyUpdating} = useProjects();
  const {openModal} = useProjectCrudModal({setModalOpen: setUpdateProjectModalOpen, project});

  const disabled = project ? isProjectCurrentlyUpdating(project) : false;

  return (
    <ProjectActionButton
      cardMarkup={<ProjectMenuUpdateButton />}
      listMarkup={<RuleFolder aria-disabled={disabled} sx={{cursor: 'pointer'}} fontSize="small" color={disabled ? 'disabled' : 'primary'} onClick={disabled ? null : openModal} />}
    />
  );
};

UpdateProjectButton.defaultProps = {
  project: null
};

UpdateProjectButton.propTypes = {
  project: projectShape
};

export default UpdateProjectButton;
