import useSnackbar from '../../hooks/providers/useSnackbar';
import CustomSnackbar from './Snackbar';

const AppSnackbars = () => {
  const {snackbars, closeSnackbar} = useSnackbar();

  return (snackbars || []).map((s, index) => {
    const snackbarsOpened = snackbars.filter(snack => snack.isOpen);
    const marginTop = snackbarsOpened.findIndex(snack => snack.id === s.id) * 7;

    return (
      <CustomSnackbar marginTop={marginTop} key={index} hasSpinner={s.hasSpinner} text={s.message} isOpen={s.isOpen} severity={s.severity} duration={s.duration} onClose={() => closeSnackbar(s.id)} />
    );
  });
};

export default AppSnackbars;
