import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {Grid} from '@mui/material';
import Box from '@mui/material/Box';
import {bool, func, string} from 'prop-types';

const TabLabel = ({label, onIconClick, disabled, isActive = false}) => {
  if (!isActive) {
    return label;
  }

  return (
    <Box
      sx={{
        filter: disabled ? 'grayscale(1)' : 'none'
      }}
    >
      <Grid container alignItems="center" flexWrap="nowrap">
        <Grid item>{label}</Grid>
        <Grid item ml={1.5} display="flex" onClick={disabled ? null : onIconClick}>
          <SettingsOutlinedIcon color="secondary" sx={{fontSize: 18}} />
        </Grid>
      </Grid>
    </Box>
  );
};

TabLabel.defaultProps = {
  isActive: false,
  onIconClick: null,
  disabled: false
};

TabLabel.propTypes = {
  label: string.isRequired,
  isActive: bool,
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: bool,
  onIconClick: func
};

export default TabLabel;
