import {DialogContentText, Typography, DialogActions, useTheme} from '@mui/material';
import Box from '@mui/material/Box';

import {DEFAULT_PROJECTS_SCHEMAS} from '../../../const';
import useProjects from '../../../hooks/providers/useProjects';
import Button from '../../form/buttons/Button/Button';
import BaseModal from '../../modal/BaseModal';

const DeleteProjectModal = () => {
  const {deleteProjectModalOpen: open, selectedProject: project, setDeleteProjectModalOpen: setOpen, deleteProject, projects, setProjects, selectedReportTab, closeProjectMenu} = useProjects();

  const isDeFiGestionProject = project.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion;
  const theme = useTheme();

  const onClose = () => {
    setOpen(false);
    closeProjectMenu();
  };

  const title = 'Supprimer la connexion';

  return (
    <BaseModal
      sx={{
        '& .MuiDialogContentText-root': {
          color: theme.palette.error.main
        }
      }}
      title={title}
      canCloseOnBackdropClick={false}
      onClose={onClose}
      open={open}
    >
      <Box p={3}>
        <DialogContentText textAlign="center">Êtes-vous sûr de vouloir supprimer définitivement cette connexion ?</DialogContentText>
      </Box>
      <DialogActions
        sx={{
          mb: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Button variant="outlined" color="primary" onClick={onClose}>
          Annuler
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            onClose();
            deleteProject(project);
          }}
        >
          Supprimer
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

export default DeleteProjectModal;
