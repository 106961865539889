import {Box, MenuItem, TextField} from '@mui/material';
import {func} from 'prop-types';

import useReport from '../../../hooks/providers/useReport';

const BudgetsSelect = ({onSelect}) => {
  const {alreadyTakenBudgetNames: budgets} = useReport();

  return (
    <Box mt={1} mb={2}>
      <TextField onChange={e => onSelect(e.target.value)} fullWidth id="outlined-select-budget" select label="Choisissez un budget ...">
        {budgets.length === 0 && (
          <MenuItem disabled value={null}>
            Pas de budget à modifier
          </MenuItem>
        )}
        {budgets.map(b => {
          return (
            <MenuItem key={b} value={b}>
              {b}
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
};

BudgetsSelect.propTypes = {
  onSelect: func.isRequired
};

export default BudgetsSelect;
