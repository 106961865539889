const REPORT_EXPORTING_STEPS = {
  EXPORTING_VISUALS: 'EXPORTING_VISUALS',
  GENERATING_FILE: 'GENERATING_FILE'
};

const ACTIONS_TYPES = {
  START_EXPORT: 'START_EXPORT',
  SET_EXPORTING_STEP: 'SET_EXPORTING_STEP',
  SET_TOTAL_PAGES: 'SET_TOTAL_PAGES',
  SET_TOTAL_VISUALS_IN_PAGE: 'SET_TOTAL_VISUALS_IN_PAGE',
  SET_NUMBER_OF_PAGES_EXPORTED: 'SET_NUMBER_OF_PAGES_EXPORTED',
  INCREMENT_NUMBER_OF_PAGES_EXPORTED: 'INCREMENT_NUMBER_OF_PAGES_EXPORTED',
  SET_NUMBER_OF_VISUALS_EXPORTED: 'SET_NUMBER_OF_VISUALS_EXPORTED',
  SET_PROGRESS_VISIBILITY: 'SET_PROGRESS_VISIBILITY',
  STOP_EXPORT: 'STOP_EXPORT',
  INCREMENT_NUMBER_OF_PAGE_VISUALS_EXPORTED: 'INCREMENT_NUMBER_OF_PAGE_VISUALS_EXPORTED'
};

const initialState = {
  isExporting: false,
  exportingStep: REPORT_EXPORTING_STEPS.EXPORTING_VISUALS,
  totalNumberOfPages: 0,
  totalNumberOfVisualsInPage: 0,
  numberOfPagesExported: 0,
  numberOfVisualsExported: 0,
  shouldDisplayVisualsExportingProgress: false
};

// eslint-disable-next-line complexity
const reportExportReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.START_EXPORT:
      return {...state, isExporting: true};
    case ACTIONS_TYPES.SET_EXPORTING_STEP:
      return {...state, exportingStep: action.payload};
    case ACTIONS_TYPES.SET_TOTAL_PAGES:
      return {...state, totalNumberOfPages: action.payload};
    case ACTIONS_TYPES.SET_TOTAL_VISUALS_IN_PAGE:
      return {...state, totalNumberOfVisualsInPage: action.payload};
    case ACTIONS_TYPES.SET_NUMBER_OF_PAGES_EXPORTED:
      return {...state, numberOfPagesExported: action.payload};
    case ACTIONS_TYPES.INCREMENT_NUMBER_OF_PAGE_VISUALS_EXPORTED:
      return {...state, numberOfVisualsExported: state.numberOfVisualsExported + 1};
    case ACTIONS_TYPES.INCREMENT_NUMBER_OF_PAGES_EXPORTED:
      return {...state, numberOfPagesExported: state.numberOfPagesExported + 1};
    case ACTIONS_TYPES.SET_NUMBER_OF_VISUALS_EXPORTED:
      return {...state, numberOfVisualsExported: action.payload};
    case ACTIONS_TYPES.SET_PROGRESS_VISIBILITY:
      return {...state, shouldDisplayVisualsExportingProgress: action.payload};
    case ACTIONS_TYPES.STOP_EXPORT:
      return initialState;
    default:
      return state;
  }
};

export {reportExportReducer, ACTIONS_TYPES, initialState, REPORT_EXPORTING_STEPS};
