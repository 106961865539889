import {PROJECTS_GROUPING_TYPES} from '../../../const';
import useProjects from '../../providers/useProjects';
import useWorkspaces from '../../providers/useWorkspaces';

const useGetSingleSectionConnections = () => {
  const {projects, connectionGroupingType, selectedReportTab} = useProjects();
  const {isDefaultWorkspace} = useWorkspaces();

  const getConnections = sectionId => {
    let filteredConnections = projects || [];

    if (isDefaultWorkspace) {
      filteredConnections =
        connectionGroupingType === PROJECTS_GROUPING_TYPES.connection ? filteredConnections.filter(p => p.category === sectionId) : filteredConnections.filter(p => p.project_name === sectionId);
    } else {
      filteredConnections = filteredConnections.filter(p => p.report_id === selectedReportTab);
    }

    return filteredConnections;
  };

  return {getConnections};
};

export default useGetSingleSectionConnections;
