import {useState, useEffect} from 'react';

import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {Fab} from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {useLocation} from 'react-router-dom';

import {DEFAULT_PROJECTS_SCHEMAS, POWER_BI_PAGES, REPORT_PAGES_WITHOUT_AUDIO} from '../../../../const';
import useAudioPlayer from '../../../../hooks/misc/useAudioPlayer';
import useReport from '../../../../hooks/providers/useReport';
import useReportSummary from '../../../../hooks/providers/useReportSummary';

// eslint-disable-next-line complexity
const ReadAudioButton = () => {
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);

  const location = useLocation();
  const {reportSelectedPage, selectedProject, selectedReport, isReportLoaded} = useReport();
  const {summaryTheme, summaryHasBeenUpdated, setSummaryTheme, getSummaryTheme, reportAudios, getReportThemeAudio} = useReportSummary();

  const shouldDisplay =
    isReportLoaded &&
    selectedProject?.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion &&
    location.pathname.startsWith('/reports') &&
    reportSelectedPage.name !== POWER_BI_PAGES.defiPreviReportTreasury.id;

  const audio = (reportAudios || []).find(a => a.theme === summaryTheme && a.siren === selectedProject?.siren);
  const {isPlaying, sound, setIsPlaying, togglePlayPause} = useAudioPlayer(audio);

  const currentPageHasAudio = !REPORT_PAGES_WITHOUT_AUDIO.includes(reportSelectedPage.name);
  const isCurrentPageWithoutAudio = REPORT_PAGES_WITHOUT_AUDIO.includes(reportSelectedPage.name);
  const readAudioButtonDisabled = isLoadingAudio || (isCurrentPageWithoutAudio && !sound) || !sound;

  useEffect(() => {
    if (currentPageHasAudio && shouldDisplay) {
      (async () => {
        const newPageSummaryTheme = await getSummaryTheme(selectedReport);
        if (newPageSummaryTheme) {
          setSummaryTheme(newPageSummaryTheme);
        }
      })();
    }
  }, [reportSelectedPage, summaryTheme]);

  const loadAudio = async () => {
    setIsLoadingAudio(true);
    setIsPlaying(false);
    const {siren} = selectedProject;
    await getReportThemeAudio({theme: summaryTheme, siren});
    setIsLoadingAudio(false);
  };

  useEffect(() => {
    setIsPlaying(false);
    if (summaryTheme && !audio && selectedProject) {
      loadAudio();
    }
  }, [summaryTheme]);

  useEffect(() => {
    setIsPlaying(false);
    if (currentPageHasAudio && summaryHasBeenUpdated && selectedProject) {
      loadAudio();
    }
  }, [summaryHasBeenUpdated]);

  if (shouldDisplay) {
    return (
      <Box mb={1}>
        <Fab aria-label="audio button" disabled={readAudioButtonDisabled} variant="contained" color="primary" onClick={togglePlayPause}>
          {isLoadingAudio && <CircularProgress data-testid="circularProgress" sx={{position: 'absolute'}} size={55} />}
          {isPlaying ? <GraphicEqIcon color="white" className="wave-animation" /> : <PlayCircleIcon color="white" />}
        </Fab>
      </Box>
    );
  }

  return null;
};

export default ReadAudioButton;
