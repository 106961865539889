const ACTIONS_TYPES = {
  DELETE_THREAD: 'deleteThread',
  DELETE_THREAD_END: 'deleteThreadEnd'
};

const ActionCreators = dispatch => ({
  deleteThread: id => {
    dispatch({type: ACTIONS_TYPES.DELETE_THREAD, payload: id});
  },
  deleteThreadEnd: id => {
    dispatch({type: ACTIONS_TYPES.DELETE_THREAD_END, payload: id});
  }
});

const initialState = {
  threadsDeleting: []
};

// eslint-disable-next-line default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.DELETE_THREAD:
      return {
        ...state,
        threadsDeleting: [...state.threadsDeleting, action.payload]
      };
    case ACTIONS_TYPES.DELETE_THREAD_END:
      return {
        ...state,
        threadsDeleting: state.threadsDeleting.filter(id => id !== action.payload)
      };

    default:
      throw new Error(`Action type ${action.type} doesn't exist`);
  }
};

export {reducer, initialState, ActionCreators};
