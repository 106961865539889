import {useMemo} from 'react';

import {Box} from '@mui/material';

import {PROJECTS_GROUPING_TYPES} from '../../const';
import useProjects from '../../hooks/providers/useProjects';
import Spinner from '../spinner/Spinner';
import ConnectionsSection from './ConnectionsSection';

const ConnectionsSections = () => {
  const {connectionGroupingType, isConnectorsLoading, projects, connectors} = useProjects();

  const sections = useMemo(() => {
    if (connectionGroupingType === PROJECTS_GROUPING_TYPES.folder) {
      return [...new Set(projects?.map(p => p.project_name) || [])];
    }
    return [...new Set(connectors.map(c => c.category))];
  }, [connectionGroupingType, projects, connectors]);

  if (connectionGroupingType === PROJECTS_GROUPING_TYPES.connection && isConnectorsLoading) {
    return <Spinner isLoading text="Chargement des connecteurs..." />;
  }

  return (
    <Box mt={3}>
      {sections.map(section => (
        <ConnectionsSection key={section} sectionId={section} />
      ))}
    </Box>
  );
};

export default ConnectionsSections;
