import {useEffect} from 'react';

import {func, oneOfType, node, bool} from 'prop-types';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import WidgetsContainer from 'components/widgets/WidgetsContainer';
import useProfile from 'hooks/providers/useProfile';

import TranscriptWorkingDayModal from './components/audio-transcript/TranscriptWorkingDayModal/TranscriptWorkingDayModal';
import ExpiredSubscriptionBanner from './components/banner/ExpiredSubscriptionBanner';
import ProjectsLimitReachedBanner from './components/banner/ProjectsLimitReachedBanner';
import TrialBanner from './components/banner/TrialBanner';
import ChatbotSidebar from './components/chatbot/ChatbotSidebar/ChatbotSidebar';
import ContactFormBackdrop from './components/contact/ContactFormBackdrop/ContactFormBackdrop';
import Header from './components/header/Header/Header';
import ReportModulesSidebar from './components/report-modules/ReportModulesSidebar/ReportModulesSidebar';
import AppSnackbars from './components/snackbar/AppSnackbars';
import Spinner from './components/spinner/Spinner';
import UploadDialog from './components/upload/UploadDialog';
import useAuth from './hooks/providers/useAuth';
import useLoading from './hooks/providers/useLoading';
import usePayment from './hooks/providers/usePayment';
import useProjects from './hooks/providers/useProjects';
import useReport from './hooks/providers/useReport';
import useSnackbar from './hooks/providers/useSnackbar';
import useUpload from './hooks/providers/useUpload';
import useWorkspaces from './hooks/providers/useWorkspaces';
import Report from './pages/Report';
import {makeHumanFriendlyUrl} from './utils/data-formatting';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: calc(100vh - 64px);
  width: 100%;
`;

const Layout = ({children}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {setSelectedReport} = useReport();
  const {isRegeneratingToken, user, isNewUser} = useAuth();
  const {profile, isConsentsLoading, getHelpButtons} = useProfile();
  const paymentContext = usePayment();
  const {snackbars} = useSnackbar();
  const {newConnectionDataIntegrationInProgress, getNewConnectionDataIntegrationInProgress, projects} = useProjects();
  const {isUploadFilesModalOpen} = useUpload();
  const {setCompletedApiCalls} = useLoading();
  const {getWorkspaces} = useWorkspaces();

  const fetchSubscriptionsAndWorkspaces = async (shouldFetchWorkspaces = false) => {
    if (user?.tokenAad) {
      paymentContext.setIsSubscriptionLoading(true);

      const promises = [paymentContext.getUserSubscriptions()];
      if (shouldFetchWorkspaces) {
        promises.push(getWorkspaces());
      }
      await Promise.all(promises);

      setCompletedApiCalls(prevCompleted => prevCompleted + 2);
      paymentContext.setIsSubscriptionLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!isNewUser) {
        await fetchSubscriptionsAndWorkspaces();
      }
    })();
  }, [user?.tokenAad]);

  useEffect(() => {
    (async () => {
      if (isNewUser && projects) {
        await fetchSubscriptionsAndWorkspaces(true);
      }
    })();
  }, [user?.tokenAad, projects]);

  useEffect(() => {
    if (!location.pathname.startsWith('/reports')) {
      setSelectedReport(null);
    }
  }, [location.pathname]);

  // This hooks gets job status concerning an ongoing connection data integration
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (newConnectionDataIntegrationInProgress) {
      getNewConnectionDataIntegrationInProgress();

      const intervalId = setInterval(() => {
        getNewConnectionDataIntegrationInProgress();
      }, 10000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [newConnectionDataIntegrationInProgress, snackbars]);

  // This hooks aims to redirect user to the onboarding document
  // Navigates new users to //base-documentaire/livret-de-bienvenue
  useEffect(() => {
    if (getHelpButtons && profile?.newUser) {
      const categories = Object.keys(getHelpButtons);
      for (let i = 0; i < categories.length; i++) {
        const categoryKey = categories[i];
        const category = getHelpButtons[categoryKey];
        const welcomeDoc = category?.find(doc => doc.is_welcome_doc);
        if (welcomeDoc && welcomeDoc.subtitle && !localStorage.getItem('welcomedocopened')) {
          localStorage.setItem('welcomedocopened', true);
          const formattedGroupName = makeHumanFriendlyUrl(categoryKey);
          const formattedDocumentName = makeHumanFriendlyUrl(welcomeDoc.subtitle);
          navigate(`/${formattedGroupName}/${formattedDocumentName}`);
          break;
        }
      }
    }
  }, [getHelpButtons, navigate, profile]);

  // Forces report container to be on a single page without scroll
  const fullHeightContainerStyle = {
    height: '100vh',
    overflow: 'hidden'
  };

  // !isUploadFilesModalOpen because this style breaks react-dropzone.
  const containerStyle = location?.pathname?.startsWith('/reports') && !isUploadFilesModalOpen ? fullHeightContainerStyle : {};

  return (
    <div style={containerStyle}>
      <Header />
      <AppSnackbars />

      <ReportModulesSidebar />

      <ChatbotSidebar>
        {isConsentsLoading || isRegeneratingToken ? (
          <SpinnerContainer>
            <Spinner withoutText size={128} isLoading />
          </SpinnerContainer>
        ) : (
          <>
            <ExpiredSubscriptionBanner />
            <ProjectsLimitReachedBanner />
            <TrialBanner />
            <>
              {children}
              <WidgetsContainer />
              <Report />
            </>
          </>
        )}
      </ChatbotSidebar>

      <UploadDialog />
      <TranscriptWorkingDayModal />
      <ContactFormBackdrop />
    </div>
  );
};
Layout.defaultProps = {
  children: null
};

Layout.propTypes = {
  children: oneOfType([node, func])
};

export default Layout;
