import {DEFAULT_WORKSPACE} from '../../const';

const ACTIONS_TYPES = {
  SET_WORKSPACES: 'setWorkspaces',
  CREATE_WORKSPACE_IN_PROGRESS: 'createWorkspaceInProgress',
  CREATE_WORKSPACE_END: 'createWorkspaceEnd',
  SET_SELECTED_WORKSPACE: 'setSelectedWorkspace',
  DELETE_WORKSPACE: 'deleteWorkspace',
  DELETE_WORKSPACE_SUCCESS: 'deleteWorkspaceSuccess',
  DELETE_WORKSPACE_FAIL: 'deleteWorkspaceFail',
  ADD_USER_TO_WORKSPACE: 'addUserToWorkspace',
  ADD_REPORT_TO_WORKSPACE: 'addReportToWorkspace',
  DELETE_REPORT_FROM_WORKSPACE: 'deleteReportFromWorkspace',
  DELETE_REPORT_FROM_WORKSPACE_SUCCESS: 'deleteReportFromWorkspaceSuccess',
  DELETE_REPORT_FROM_WORKSPACE_FAIL: 'deleteReportFromWorkspaceFail',
  DELETE_USER_FROM_WORKSPACE: 'deleteUserFromWorkspace',
  ADD_USER_TO_REPORT: 'addUserToReport',
  DELETE_USER_FROM_REPORT: 'deleteUserFromReport',
  REPLACE_REPORT_IN_WORKSPACE: 'replaceReportInWorkspace'
};

const ActionCreators = dispatch => ({
  setWorkspaces: ws => {
    dispatch({type: ACTIONS_TYPES.SET_WORKSPACES, payload: ws});
  },
  createWorkspaceInProgress: () => {
    dispatch({type: ACTIONS_TYPES.CREATE_WORKSPACE_IN_PROGRESS});
  },
  createWorkspaceEnd: () => {
    dispatch({type: ACTIONS_TYPES.CREATE_WORKSPACE_END});
  },
  addUserToWorkspace: (workspaceId, user) => {
    dispatch({
      type: ACTIONS_TYPES.ADD_USER_TO_WORKSPACE,
      payload: {
        workspaceId,
        user
      }
    });
  },
  deleteUserFromWorkspace: (workspaceId, userId) => {
    dispatch({
      type: ACTIONS_TYPES.DELETE_USER_FROM_WORKSPACE,
      payload: {workspaceId, userId}
    });
  },
  addUserToReport: (workspaceId, reportId, user) => {
    dispatch({
      type: ACTIONS_TYPES.ADD_USER_TO_REPORT,
      payload: {workspaceId, reportId, user}
    });
  },
  deleteUserFromReport: (workspaceId, reportId, userId) => {
    dispatch({
      type: ACTIONS_TYPES.DELETE_USER_FROM_REPORT,
      payload: {workspaceId, reportId, userId}
    });
  },
  deleteReportFromWorkspace: reportId => {
    dispatch({
      type: ACTIONS_TYPES.DELETE_REPORT_FROM_WORKSPACE,
      payload: reportId
    });
  },
  deleteReportFromWorkspaceSuccess: (workspaceId, reportId) => {
    dispatch({
      type: ACTIONS_TYPES.DELETE_REPORT_FROM_WORKSPACE_SUCCESS,
      payload: {workspaceId, reportId}
    });
  },
  deleteReportFromWorkspaceFail: reportId => {
    dispatch({
      type: ACTIONS_TYPES.DELETE_REPORT_FROM_WORKSPACE_FAIL,
      payload: reportId
    });
  },
  addReportToWorkspace: (workspaceId, data, isCustomReport = false) => {
    dispatch({
      type: ACTIONS_TYPES.ADD_REPORT_TO_WORKSPACE,
      payload: {workspaceId, data, isCustomReport}
    });
  },
  setSelectedWorkspace: workspaceId => {
    dispatch({type: ACTIONS_TYPES.SET_SELECTED_WORKSPACE, payload: workspaceId});
  },
  deleteWorkspace: wsId => {
    dispatch({type: ACTIONS_TYPES.DELETE_WORKSPACE, payload: wsId});
  },
  deleteWorkspaceSuccess: wsId => {
    dispatch({type: ACTIONS_TYPES.DELETE_WORKSPACE_SUCCESS, payload: wsId});
  },
  deleteWorkspaceFail: wsId => {
    dispatch({type: ACTIONS_TYPES.DELETE_WORKSPACE_FAIL, payload: wsId});
  },
  replaceReportInWorkspace: (workspaceId, newReport) => {
    dispatch({
      type: ACTIONS_TYPES.REPLACE_REPORT_IN_WORKSPACE,
      payload: {workspaceId, newReport}
    });
  }
});

const initialState = {
  workspaces: [DEFAULT_WORKSPACE],
  isCreatingWorkspace: false,
  workspacesDeleting: [],
  reportsDeleting: []
};

// eslint-disable-next-line complexity,default-param-last
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS_TYPES.SET_WORKSPACES:
      return {
        ...state,
        workspaces: action.payload
      };

    case ACTIONS_TYPES.CREATE_WORKSPACE_IN_PROGRESS:
      return {
        ...state,
        isCreatingWorkspace: true
      };

    case ACTIONS_TYPES.CREATE_WORKSPACE_END:
      return {
        ...state,
        isCreatingWorkspace: false
      };

    case ACTIONS_TYPES.ADD_USER_TO_WORKSPACE:
      return {
        ...state,
        workspaces: state.workspaces.map(workspace =>
          workspace.workspace_id === action.payload.workspaceId
            ? {
                ...workspace,
                workspace_users: [...workspace.workspace_users, action.payload.user] // Create a new array
              }
            : workspace
        )
      };

    case ACTIONS_TYPES.DELETE_USER_FROM_WORKSPACE: {
      const {workspaceId, userId} = action.payload;

      return {
        ...state,
        workspaces: state.workspaces.map(workspace =>
          workspace.workspace_id === workspaceId
            ? {
                ...workspace,
                workspace_users: workspace.workspace_users.filter(user => user.user_id !== userId)
              }
            : workspace
        )
      };
    }

    case ACTIONS_TYPES.ADD_USER_TO_REPORT: {
      const {workspaceId, reportId, user} = action.payload;

      return {
        ...state,
        workspaces: state.workspaces.map(workspace =>
          workspace.workspace_id === workspaceId
            ? {
                ...workspace,
                reports: workspace.reports.map(report =>
                  report.report_id === reportId
                    ? {
                        ...report,
                        report_users: [...(report.report_users ?? []), user]
                      }
                    : report
                )
              }
            : workspace
        )
      };
    }

    case ACTIONS_TYPES.DELETE_USER_FROM_REPORT: {
      const {workspaceId, reportId, userId} = action.payload;

      return {
        ...state,
        workspaces: state.workspaces.map(workspace =>
          workspace.workspace_id === workspaceId
            ? {
                ...workspace,
                reports: workspace.reports.map(report =>
                  report.report_id === reportId
                    ? {
                        ...report,
                        report_users: report.report_users.filter(user => user.user_id !== userId)
                      }
                    : report
                )
              }
            : workspace
        )
      };
    }

    case ACTIONS_TYPES.DELETE_REPORT_FROM_WORKSPACE:
      return {
        ...state,
        reportsDeleting: [...state.reportsDeleting, action.payload]
      };

    case ACTIONS_TYPES.DELETE_REPORT_FROM_WORKSPACE_SUCCESS: {
      const {workspaceId, reportId} = action.payload;

      return {
        ...state,
        reportsDeleting: state.reportsDeleting.filter(id => id !== reportId),
        workspaces: state.workspaces.map(workspace =>
          workspace.workspace_id === workspaceId
            ? {
                ...workspace,
                reports: workspace.reports.filter(report => report.report_id !== reportId)
              }
            : workspace
        )
      };
    }

    case ACTIONS_TYPES.DELETE_REPORT_FROM_WORKSPACE_FAIL:
      return {
        ...state,
        reportsDeleting: state.reportsDeleting.filter(id => id !== action.payload)
      };

    case ACTIONS_TYPES.ADD_REPORT_TO_WORKSPACE: {
      const {workspaceId, data, isCustomReport} = action.payload;
      const {reportName, reportId, reportSchemaName} = data;

      return {
        ...state,
        workspaces: state.workspaces.map(workspace =>
          workspace.workspace_id === workspaceId
            ? {
                ...workspace,
                reports: [
                  ...workspace.reports,
                  {
                    report_name: reportName,
                    report_id: reportId,
                    isCustomModel: isCustomReport,
                    schema_name: reportSchemaName
                  }
                ]
              }
            : workspace
        )
      };
    }

    case ACTIONS_TYPES.SET_SELECTED_WORKSPACE:
      return {
        ...state,
        workspaces: state.workspaces.map(w => ({
          ...w,
          isSelected: w.workspace_id === action.payload
        }))
      };

    case ACTIONS_TYPES.DELETE_WORKSPACE:
      return {
        ...state,
        workspacesDeleting: [...state.workspacesDeleting, action.payload]
      };

    case ACTIONS_TYPES.DELETE_WORKSPACE_SUCCESS: {
      const newWorkspaces = state.workspaces
        .filter(ws => ws.workspace_id !== action.payload)
        .map((w, i) => ({
          ...w,
          isSelected: i === 0
        }));

      return {
        ...state,
        workspaces: newWorkspaces,
        workspacesDeleting: state.workspacesDeleting.filter(id => id !== action.payload)
      };
    }

    case ACTIONS_TYPES.DELETE_WORKSPACE_FAIL:
      return {
        ...state,
        workspacesDeleting: state.workspacesDeleting.filter(id => id !== action.payload)
      };
    case ACTIONS_TYPES.REPLACE_REPORT_IN_WORKSPACE: {
      const {workspaceId, newReport} = action.payload;

      return {
        ...state,
        workspaces: state.workspaces.map(workspace =>
          workspace.workspace_id === workspaceId
            ? {
                ...workspace,
                reports: workspace.reports.map(report => (report.report_name === newReport.report_name ? newReport : report))
              }
            : workspace
        )
      };
    }

    default:
      console.error(`Action type ${action.type} doesn't exist`);
      return {state};
  }
};

export {reducer, initialState, ActionCreators};
