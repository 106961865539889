import DotsIcon from '@mui/icons-material/MoreVertOutlined';
import {ListItemIcon, ListItemText, Tooltip, useTheme} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import {shape, string} from 'prop-types';

import useChatbot from '../../../../hooks/providers/useChatbot';

const ConversationItem = ({thread}) => {
  const {isThreadCurrentlyDeleting, isThreadLoading: isLoading, openThreadMenu, switchConversation, currentThreadId} = useChatbot();
  const isSelected = thread.thread_id === currentThreadId;

  const theme = useTheme();
  const textColor = isSelected ? '#FFF' : 'text.primary';
  const backgroundColor = isSelected ? theme.palette.primary.main : '#FFF';

  const handleOnClick = () => {
    if (!isLoading) {
      switchConversation(thread.thread_id);
    }
  };

  const isDeleting = isThreadCurrentlyDeleting(thread.thread_id);

  return (
    <Tooltip placement="right-start" arrow title={thread.thread_name}>
      <ListItemButton
        disabled={isDeleting || (isLoading && !isSelected)}
        onClick={handleOnClick}
        sx={{
          backgroundColor,
          pl: 2,
          pr: 1,
          py: 0.5,
          '&.MuiButtonBase-root:hover': isSelected
            ? {
                bgcolor: backgroundColor
              }
            : {}
        }}
      >
        <ListItemText
          sx={{
            cursor: 'pointer'
          }}
          primary={thread.thread_name}
          primaryTypographyProps={{
            noWrap: true,
            fontSize: 14,
            color: textColor,
            fontFamily: isSelected ? 'InstrumentSansBold' : 'InstrumentSans',
            style: {overflow: 'hidden', textOverflow: 'ellipsis', width: 220}
          }}
        />
        <ListItemIcon sx={{justifyContent: 'flex-end', cursor: 'pointer'}}>
          <DotsIcon color={isSelected ? 'white' : 'primary'} onClick={e => openThreadMenu(e, thread.thread_id)} />
        </ListItemIcon>
      </ListItemButton>
    </Tooltip>
  );
};

ConversationItem.propTypes = {
  thread: shape({
    thread_id: string.isRequired,
    thread_name: string.isRequired
  }).isRequired
};

export default ConversationItem;
