import {Box} from '@mui/material';
import muiStyled from '@mui/material/styles/styled';

import ReadAudioButton from '../embed/report-buttons/ReadAudioButton/ReadAudioButton';
import ChatbotWidget from './ChatbotWidget';
import RecordDayWidget from './RecordDayWidget';
import ReportModulesWidget from './ReportModulesWidget';

const WidgetsContainer = muiStyled(Box)(() => ({
  position: 'fixed',
  bottom: 16,
  right: 16,
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column'
}));

// If we have more widgets in the future we can add an Accordion button especially for mobile
const Widgets = () => {
  return (
    <WidgetsContainer>
      <ReadAudioButton />
      <RecordDayWidget />
      <ReportModulesWidget />
      <ChatbotWidget />
    </WidgetsContainer>
  );
};

export default Widgets;
