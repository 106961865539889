import {useMemo} from 'react';

import {Box} from '@mui/material';
import {string} from 'prop-types';

import {PROJECTS_GROUPING_TYPES, PROJECTS_VIEW_TYPES} from '../../const';
import {useConnectionFiltering} from '../../hooks/components/connections/useConnectionsFiltering';
import useGetSingleSectionConnections from '../../hooks/components/connections/useGetSingleSectionConnections';
import useProjects from '../../hooks/providers/useProjects';
import {sortProjectsAlphabeticallyByConnectorName} from '../../utils';
import ConnectionsGrid from './ConnectionsGrid';
import ConnectionsList from './ConnectionsList';
import ConnectorsCategoryTitle from './create/ConnectorsCategoryTitle/ConnectorsCategoryTitle';

const ConnectionsSection = ({sectionId}) => {
  const {collapsedCategories, setCollapsedCategories, connectionGroupingType, viewType, projects, isLoading} = useProjects();
  const {getFilteredConnections} = useConnectionFiltering();
  const {getConnections} = useGetSingleSectionConnections();
  const sectionConnections = getConnections(sectionId);

  const orderedConnections = connectionGroupingType === PROJECTS_GROUPING_TYPES.folder ? sortProjectsAlphabeticallyByConnectorName(sectionConnections || []) : sectionConnections;
  const connections = getFilteredConnections(orderedConnections);

  const isCategoryOpen = !collapsedCategories.includes(sectionId);

  const toggleCategory = () => {
    setCollapsedCategories(prev => (prev.includes(sectionId) ? prev.filter(c => c !== sectionId) : [...prev, sectionId]));
  };

  const projectsLoaded = projects?.length > 0 && !isLoading;

  if (!projectsLoaded) return null;
  if (!isCategoryOpen)
    return (
      <Box mt={3}>
        <ConnectorsCategoryTitle withArrow category={sectionId} onArrowClick={toggleCategory} />
      </Box>
    );
  if (connections.length === 0) return null;

  return (
    <Box mt={3}>
      <ConnectorsCategoryTitle withArrow category={sectionId} onArrowClick={toggleCategory} />
      {viewType === PROJECTS_VIEW_TYPES.card ? <ConnectionsGrid connections={connections} /> : <ConnectionsList connections={connections} />}
    </Box>
  );
};
ConnectionsSection.propTypes = {
  sectionId: string.isRequired
};

export default ConnectionsSection;
