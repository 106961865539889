import {useEffect} from 'react';

import useAuth from '../../providers/useAuth';
import useLoading from '../../providers/useLoading';
import useProjects from '../../providers/useProjects';
import useWorkspaces from '../../providers/useWorkspaces';

const useFetchConnectionsAndWorkspaces = () => {
  const auth = useAuth();
  const {shouldFetchProjects, setShouldFetchProjects, fetchProjects} = useProjects();
  const {getWorkspaces} = useWorkspaces();
  const {setCompletedApiCalls} = useLoading();

  useEffect(() => {
    (async () => {
      if (auth?.user?.tokenAad && shouldFetchProjects) {
        if (auth.isNewUser) {
          await fetchProjects();
        } else {
          await Promise.all([getWorkspaces(), fetchProjects()]);
        }
        setCompletedApiCalls(prev => prev + 2);
        setShouldFetchProjects(false);
      }
    })();
  }, [auth?.user?.tokenAad, shouldFetchProjects]);
};

export default useFetchConnectionsAndWorkspaces;
