import {DialogActions, DialogContent, Typography} from '@mui/material';
import {bool, func, string} from 'prop-types';

import Button from '../../form/buttons/Button/Button';
import BaseModal from '../../modal/BaseModal';

const ImportedReportNameAlreadyExistsModal = ({isOpen, onClose, onSubmit, reportName}) => {
  const handleSubmit = async () => {
    onClose();
    await onSubmit();
  };

  const title = `Rapport ${reportName} existant`;

  return (
    <BaseModal onClose={onClose} maxWidth="sm" open={isOpen} title={title}>
      <DialogContent sx={{p: 3}}>
        <Typography>
          Cette action <b> écrasera {reportName}</b>. Confirmez-vous ?
        </Typography>
      </DialogContent>
      <DialogActions sx={{pb: 1}}>
        <Button variant="contained" color="error" onClick={handleSubmit}>
          Remplacer le rapport
        </Button>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Annuler
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

ImportedReportNameAlreadyExistsModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  reportName: string.isRequired
};

export default ImportedReportNameAlreadyExistsModal;
