import {Grid} from '@mui/material';
import {arrayOf} from 'prop-types';

import {projectShape} from '../../proptypes-shapes';
import Project from './single-project-item/Project';

const ConnectionsGrid = ({connections}) => (
  <Grid mt={2} container spacing={2} justifyContent="center" alignItems="stretch">
    {connections.map((project, index) => (
      <Grid key={index} item xs={12} sm={6} md={4} display="flex">
        <Project project={project} />
      </Grid>
    ))}
  </Grid>
);

ConnectionsGrid.propTypes = {
  connections: arrayOf(projectShape).isRequired
};

export default ConnectionsGrid;
