/* eslint-disable no-case-declarations */
import {useEffect, useMemo, useState} from 'react';

import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS, CONNECTORS, DEFAULT_PROJECTS_SCHEMAS, PROJECTS_GROUPING_TYPES, PROJECTS_VIEW_TYPES, REGULAR_EXPRESSIONS, SNACKBAR_ACTIONS} from '../const';
import ProjectsContext from '../contexts/ProjectsContext';
import useHttp from '../hooks/misc/useHttp';
import {usePersistedReducer} from '../hooks/misc/usePersistedReducer';
import usePayment from '../hooks/providers/usePayment';
import useReport from '../hooks/providers/useReport';
import useSnackbar from '../hooks/providers/useSnackbar';
import useWorkspaces from '../hooks/providers/useWorkspaces';
import {ActionCreators, initialState, reducer} from '../reducers/providers/projectsReducer';
import {generateTiimeLoginRedirectUrl, getFileB64, getManualConnectorFilename, sortProjectsAlphabetically} from '../utils';

const ProjectsProvider = ({children}) => {
  const paymentContext = usePayment();
  const reportsContext = useReport();
  const {shouldRegenerateToken, setShouldRegenerateToken, loadReports} = reportsContext;
  const {isDefaultWorkspace, selectedWorkspace} = useWorkspaces();
  const {_post, _get} = useHttp();
  const {showSnackbar, closeSnackbar, setSnackbars, defaultSnackbarOptions, snackbars} = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [createError, setCreateError] = useState('');
  const [createProjectApiKeyError, setCreateProjectApiKeyError] = useState('');
  const [updateProjectUsersError, setUpdateProjectUsersError] = useState('');
  const [updateProjectUsersSuccess, setUpdateProjectUsersSuccess] = useState(false);
  const [createProjectUsername, setCreateProjectUsername] = useState('');
  const [createProjectPassword, setCreateProjectPassword] = useState('');
  const [createProjectApiKey, setCreateProjectApiKey] = useState('');
  const [importFecFileError, setImportFecFileError] = useState('');
  const [selectedReportTab, setSelectedReportTab] = useState();
  const [createProjectSiren, setCreateProjectSiren] = useState('');
  const [isSirenValid, setIsSirenValid] = useState(false);
  const [projectCreated, setProjectCreated] = useState(false);
  const [isCegidFormValid, setIsCegidFormValid] = useState(false);
  const [createProjectSirenError, setCreateProjectSirenError] = useState('');
  const [shouldFetchProjects, setShouldFetchProjects] = useState(true);
  const [searchedConnectionTerm, setSearchedConnectionTerm] = useState('');

  const connectionsReducerKey = 'CONNECTION_REDUCER_KEY';
  const {state, dispatch} = usePersistedReducer(reducer, initialState, connectionsReducerKey);
  const {projects} = state;

  const {setDataIntegrationJobUrl, setConnections, deleteConnection, deleteConnectionSuccess, deleteConnectionFail, updateConnection, updateConnectionFail, updateConnectionSuccess} =
    ActionCreators(dispatch);

  const [projectsViewType, setProjectsViewType] = useState(PROJECTS_VIEW_TYPES.card);
  const [connectionGroupingType, setConnectionGroupingType] = useState(PROJECTS_GROUPING_TYPES.folder);
  const [deleteProjectModalOpen, setDeleteProjectModalOpen] = useState(false);
  const [updateProjectModalOpen, setUpdateProjectModalOpen] = useState(false);
  const [manageProjectUsersModalOpen, setManageProjectUsersModalOpen] = useState(false);
  const [projectCardMenuAnchorEl, setProjectCardMenuAnchorEl] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectsSirenCurrentlyRefreshing, setProjectsSirenCurrentlyRefreshing] = useState([]);
  const [isProjectCreationSubmitting, setIsProjectCreationSubmitting] = useState(false);
  const [connector, setConnector] = useState(null);
  const [actualizeProjectDataDatePickerOpen, setActualizeProjectDataDatePickerOpen] = useState(false);
  const [isActualizeProjectMenuOpen, setIsActualizeProjectMenuOpen] = useState(null);
  const [connectors, setConnectors] = useState([]);
  const [collapsedCategories, setCollapsedCategories] = useState([]);
  const [loginToConnectorFormData, setLoginToConnectorFormData] = useState({});
  const [isLoggingToConnector, setIsLoggingToConnector] = useState(false);
  const [isSuccessfullyLoggedInToConnector, setIsSuccessfullyLoggedInToConnector] = useState(false);
  const [connectorFolders, setConnectorFolders] = useState([]);
  const [foldersCompanyIdsToConnect, setFoldersCompanyIdsToConnect] = useState([]);
  const [createProjectModalOpen, setCreateProjectModalOpen] = useState(false);
  const [isConnectorsLoading, setIsConnectorsLoading] = useState(false);
  const [isRedirectingToTiimeAuthenticationServer, setIsRedirectingToTiimeAuthenticationServer] = useState(false);
  const [isGeneratingTiimeToken, setIsGeneratingTiimeToken] = useState(false);
  const [maximumNumberOfProjectsReachedModalOpen, setMaximumNumberOfProjectsReachedModalOpen] = useState(false);
  const [connectionsCurrentlyDeleting, setConnectionsCurrentlyDeleting] = useState([]);

  // When a custom report is deleted, we have to set a new selected tab within current workspace.
  // This hook aims to automatically set a new selected tab after custom report deletion.
  useEffect(() => {
    if (!isDefaultWorkspace && selectedWorkspace.reports.length > 0) {
      const noticeReport = selectedWorkspace.reports.find(r => r.is_notice_report);
      if (noticeReport) {
        setSelectedReportTab(noticeReport.report_id);
      } else {
        setSelectedReportTab(selectedWorkspace.reports[0].report_id);
      }
    }
  }, [selectedWorkspace.reports]);

  useEffect(() => {
    (async () => {
      if (shouldRegenerateToken) {
        await loadReports();
        setShouldRegenerateToken(false);
      }
    })();
  }, [shouldRegenerateToken]);

  const checkSirenValidity = sirenInput => {
    setIsSirenValid(REGULAR_EXPRESSIONS.siren.test(sirenInput));
  };

  const handleSirenChange = e => {
    const sirenWithoutSpaces = e.target.value.replaceAll(' ', '');
    setCreateProjectSiren(sirenWithoutSpaces);
    checkSirenValidity(sirenWithoutSpaces);
  };

  const incrementProjectsOwnedByUser = () => {
    paymentContext.setNumberOfProjectsOwnedByUser(prevNumber => prevNumber + 1);
  };

  const decrementProjectsOwnedByUser = () => {
    paymentContext.setNumberOfProjectsOwnedByUser(prevNumber => prevNumber - 1);
  };

  const fetchProjects = async () => {
    setIsLoading(true);

    const url = API_ENDPOINTS.projects.findAll;
    try {
      const {response: projectsResponse, responseJson: data} = await _get(url);

      if (projectsResponse.ok) {
        const projectsWithStringifiedSirens = data.map(p => ({...p, siren: p.siren.toString()}));
        setConnections(sortProjectsAlphabetically(projectsWithStringifiedSirens));

        setIsLoading(false);
      } else {
        // eslint-disable-next-line no-console
        console.error({projectsResponse});
        if (projectsResponse.status === 401) {
          setShouldFetchProjects(true);
        }
        throw Error('Error during projects fecthing');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  // TODO Wait for Adrian's decision but apparently it will completely disappear
  // eslint-disable-next-line complexity
  const createProject = async project => {
    const {siren} = project;
    try {
      setCreateError('');
      setCreateProjectApiKeyError('');
      setCreateProjectSirenError('');

      const url = API_ENDPOINTS.projects.create.manual;
      const reportId = isDefaultWorkspace ? null : selectedReportTab;
      const schema = isDefaultWorkspace ? selectedReportTab : selectedWorkspace.schema_name;

      const {response, responseJson: data} = await _post(url, {
        siren,
        report_id: reportId,
        schema,
        ...(connector === CONNECTORS.manual && {first_fiscal_month: 1})
      });

      // Business rule: If projects.length passes from 0 to 1 , or from 1 to 0 in a given module, we should regenerate token
      const numberOfProjectInSchemaBefore = projects.filter(p => p.schema_name === selectedReportTab).length;
      const numberOfProjectInSchemaAfter = numberOfProjectInSchemaBefore + 1;

      if (numberOfProjectInSchemaBefore === 0 && numberOfProjectInSchemaAfter === 1) {
        setShouldRegenerateToken(true);
      }

      switch (response.status) {
        case 200:
          // TODO handle this diffrently. We might be able to manually rebuild projects list without fetching again.
          await fetchProjects(); // We re-fetch projects so the projects page is up-to-date
          showSnackbar(SNACKBAR_ACTIONS.CREATE_PROJECT_SUCCESS);
          // Business rule : Projects created within custom workspace are not counted on creation
          // So we must not increment projects counter when creating a project within a custom ws.
          if (isDefaultWorkspace) {
            incrementProjectsOwnedByUser();
          }
          return {
            message: data,
            status: 200
          };
        case 201:
          // TODO handle this diffrently. We might be able to manually rebuild projects list without fetching again.
          await fetchProjects(); // We re-fetch projects so the projects page is up-to-date
          showSnackbar(data.message || SNACKBAR_ACTIONS.CREATE_PROJECT_SUCCESS_WITH_NAME, defaultSnackbarOptions, {createdProjectName: data.project_name});
          incrementProjectsOwnedByUser();
          return {
            message: data,
            status: 201
          };
        case 202:
          // setPennylaneDataIntegrationSiren(siren);
          // setPennylaneDataIntegrationJobUrl(data.statusQueryGetUri);
          // TODO handle this diffrently. We might be able to manually rebuild projects list without fetching again.
          await fetchProjects(); // We re-fetch projects so the projects page is up-to-date
          showSnackbar(SNACKBAR_ACTIONS.CREATE_CONNECTION_DATA_INTEGRATION_IN_PROGRESS, {
            // TODO see with adrian to change this message, or make it dynamic, or use backend message
            severity: 'info',
            autoHide: false
          });
          return {
            message: data,
            status: 202
          };
        case 203:
        case 403:
          setCreateProjectApiKeyError(data);
          return {
            message: data,
            status: response.status
          };
        case 400:
          if (selectedReportTab === DEFAULT_PROJECTS_SCHEMAS.previ) {
            setCreateError(data);
          } else {
            setCreateProjectSirenError(data);
          }
          return {
            message: data,
            status: 400
          };
        case 502:
          throw Error("Une erreur d'intégration à eu lieu. Nos équipes sont prévenues et le problème est en cours de résolution. Pour toutes questions, vous pouvez nous contacter via le chat");
        default:
          throw Error(data?.message || data);
      }
    } catch (e) {
      setCreateError(e.message);
      return {
        message: e.message
      };
    }
  };

  const checkConnectorConnection = async connectorId => {
    try {
      setConnectorFolders([]);
      const url = API_ENDPOINTS.projects.verifyConnection;
      const {response, responseJson: data} = await _post(url, {
        connector: connectorId
      });

      if (response.status === 400 && connectorId === CONNECTORS.tiime) {
        setIsRedirectingToTiimeAuthenticationServer(true);
        const loginUrl = generateTiimeLoginRedirectUrl();
        window.location.href = loginUrl;
        return;
      }

      if (response.status === 200) {
        setIsSuccessfullyLoggedInToConnector(true);
        setConnectorFolders(data);
      }

      return {
        success: response.status === 200,
        data
      };
    } catch (e) {
      return {
        success: false,
        message: e.message
      };
    }
  };

  const loginToConnector = async (connectorId, credentials) => {
    try {
      setConnectorFolders([]);
      const url = API_ENDPOINTS.projects.loginToConnector;
      const {response, responseJson: data} = await _post(url, {
        connector: connectorId,
        ...credentials
      });

      if (response.status === 200) {
        setConnectorFolders(data);
      }

      return {
        success: response.status === 200,
        data
      };
    } catch (e) {
      return {
        success: false,
        message: e.message
      };
    }
  };

  const createConnection = async (connectorId, companies, files = []) => {
    try {
      const url = API_ENDPOINTS.projects.connectFolders;
      const formattedFiles = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const f of files) {
        // eslint-disable-next-line no-await-in-loop
        const b64 = await getFileB64(f);
        const formattedFileForApi = b64.split('base64,')[1];

        formattedFiles.push({
          filename: f.name,
          file_b64: formattedFileForApi
        });
      }

      const {response, responseJson: data} = await _post(url, {
        ...(files.length > 0 ? {files: formattedFiles} : {}),
        connector: connectorId,
        companies,
        months: 3 // TODO Backend is not ready yet
      });

      if (response.status === 202) {
        setDataIntegrationJobUrl(data.statusQueryGetUri);
        setCreateProjectModalOpen(false);
      }

      return {
        success: response.status === 200,
        data
      };
    } catch (e) {
      return {
        success: false,
        message: e.message
      };
    }
  };

  const updateProject = async project => {
    const {siren, logo, name, month, schema_name: schema} = project;
    try {
      const integerMonth = parseInt(month, 10);

      const url = API_ENDPOINTS.projects.update;
      const snackbarId = showSnackbar(SNACKBAR_ACTIONS.UPDATE_PROJECT_IN_PROGRESS, {
        severity: 'info',
        autoHide: false
      });

      updateConnection(project);

      const {response, responseJson: data} = await _post(url, {
        siren,
        url_logo: logo,
        project_name: name,
        first_fiscal_month: integerMonth,
        schema
      });

      closeSnackbar(snackbarId);

      if (response.status === 200) {
        updateConnectionSuccess({
          ...project,
          siren,
          url_logo: logo,
          project_name: name,
          first_fiscal_month: integerMonth,
          schema
        });
        const projectBeforeUpdate = projects.find(p => p.siren === siren);
        const firstFiscalMonthHasChanged = integerMonth !== projectBeforeUpdate.first_fiscal_month;
        showSnackbar(SNACKBAR_ACTIONS.UPDATE_PROJECT_SUCCESS, defaultSnackbarOptions, {firstFiscalMonthHasChanged});

        return {
          success: true,
          message: data?.message || data,
          status: 200
        };
      }
      updateConnectionFail(project);
      showSnackbar(data?.message || data, {severity: 'error'});
      return {success: false};
    } catch (e) {
      return {
        message: e.message
      };
    }
  };

  const addUser = (siren, schema, user) => {
    const projectsBefore = [...projects];
    const projectIndex = projectsBefore.findIndex(p => p.siren === siren && p.schema_name === schema);
    const project = projectsBefore[projectIndex];
    project.users = [
      ...project.users,
      {
        username: user.username,
        user_id: user.userId,
        is_owner: false
      }
    ];
    projectsBefore[projectIndex] = project;
  };

  const addUserToProject = async userData => {
    try {
      const {email, siren, schema} = userData;
      setUpdateProjectUsersError('');

      const url = API_ENDPOINTS.projects.addUser;
      const {response, responseJson: data} = await _post(url, {
        guest_usermail: email,
        siren,
        schema
      });

      if (response.status === 200) {
        addUser(siren, schema, {
          username: data.givenName,
          userId: data.id
        });
        showSnackbar(SNACKBAR_ACTIONS.ADD_USER_SUCCESS, defaultSnackbarOptions, {newUserUsername: data.givenName});
      }

      if (response.status === 200 || response.status === 402) {
        const user = {
          username: data.givenName,
          userId: data.id
        };
        return {
          message: data,
          status: response.status,
          user: response.status === 200 ? user : null
        };
      }

      setUpdateProjectUsersError(data?.message || data);
      return {
        status: response.status,
        success: false
      };
    } catch (e) {
      setUpdateProjectUsersError(e);
      return {
        message: e
      };
    }
  };

  const deleteUser = (siren, schema, userIdToRemove) => {
    const projectsBefore = [...projects];
    const projectIndex = projectsBefore.findIndex(p => p.siren === siren && p.schema_name === schema);
    const project = projectsBefore[projectIndex];
    project.users = project.users.filter(u => u.user_id !== userIdToRemove);
    projectsBefore[projectIndex] = project;
  };

  const deleteUserFromProject = async (userData, isUserDeletingHimself = false) => {
    try {
      setUpdateProjectUsersError('');
      const {userId, siren, schema} = userData;

      const url = API_ENDPOINTS.projects.deleteUser;
      const {response, responseJson: data} = await _post(url, {
        delete_user_id: userId,
        siren,
        schema
      });

      if (response.status === 200) {
        // We have to remove project from projects list if user deleted himself
        if (isUserDeletingHimself) {
          setConnections(previousProjects => previousProjects.filter(p => p.siren !== siren));
        } else {
          deleteUser(siren, schema, userId);
        }
        showSnackbar(SNACKBAR_ACTIONS.DELETE_USER_SUCCESS);

        return {
          message: data,
          status: 200
        };
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setUpdateProjectUsersError(e.message);
      return {
        message: e
      };
    }
  };

  const promoteOrDemoteUser = async userData => {
    const {siren, userId, isOwner, schema} = userData;
    try {
      const url = API_ENDPOINTS.users.promoteOrDemote;
      const {response, responseJson: data} = await _post(url, {
        siren,
        owner_id: userId,
        is_owner: isOwner,
        schema
      });

      const project = projects.find(p => p.siren === siren && p.schema_name === schema);

      const {username} = project.users.find(u => u.user_id === userId);

      if (response.status === 200) {
        showSnackbar(isOwner ? SNACKBAR_ACTIONS.PROMOTE_USER_SUCCESS : SNACKBAR_ACTIONS.DEMOTE_USER_SUCCESS, defaultSnackbarOptions, {
          promotedUserEmail: isOwner ? username : undefined,
          demotedUserEmail: !isOwner ? username : undefined
        });
        return {
          message: data,
          status: response.status
        };
      }
      if (response.status === 400) {
        showSnackbar(data, {severity: 'error', duration: 4000});
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e
      };
    }
  };

  // eslint-disable-next-line complexity
  const deleteProject = async project => {
    try {
      const schema = project.schema_name;
      const url = API_ENDPOINTS.projects.delete;
      const snackbarId = showSnackbar(SNACKBAR_ACTIONS.DELETE_PROJECT_IN_PROGRESS, {
        severity: 'info',
        autoHide: false
      });

      deleteConnection(project);

      const {response, responseJson: data} = await _post(url, {
        siren: project.siren,
        schema: isDefaultWorkspace ? schema : selectedWorkspace.schema_name,
        report_id: project.report_id
      });

      if (response.status !== 200) {
        deleteConnectionFail(project);
        closeSnackbar(snackbarId);
        showSnackbar(data?.message || data, {severity: 'error', duration: 4000});
      } else {
        deleteConnectionSuccess(project);

        // Business rule : Projects created within custom workspace are not counted on creation
        // So we must not decrement projects counter when deleting a custom ws project.
        // PS : only custom ws projects have a report_id
        if (!project.report_id) {
          decrementProjectsOwnedByUser();
        }

        // Business rule: DeFi Previ projects must be deleted automatically when associated DeFi Gestion project is deleted.
        // If a DeFi gestion project is deleted and a DeFi Previ project exists within the same siren
        // Same logic applies for projects created in custom workspaces. Because they are created from DeFi Gestion projects,
        // They must be deleted if "source" project is deleted
        const associatedProjects = schema === DEFAULT_PROJECTS_SCHEMAS.gestion ? projects.filter(p => p.siren === project.siren && p.schema_name !== DEFAULT_PROJECTS_SCHEMAS.gestion) : [];
        if (associatedProjects.length > 0) {
          const deleteAssociatedProjectsPromises = associatedProjects.map(p => deleteProject(p));
          await Promise.all(deleteAssociatedProjectsPromises);
        }

        // Business rule: If projects.length passes from 0 to 1 , or from 1 to 0 in a given module, we should regenerate token
        const numberOfProjectInSchemaBefore = projects.filter(p => p.schema_name === schema).length;
        const numberOfProjectInSchemaAfter = numberOfProjectInSchemaBefore - 1;

        if (numberOfProjectInSchemaBefore === 1 && numberOfProjectInSchemaAfter === 0) {
          setShouldRegenerateToken(true);
        }

        const projectToDeleteIndex = projects.findIndex(p => p.siren === project.siren && (p.schema_name === project.schema_name || p.report_id === selectedReportTab));

        let newProjects = [...projects];
        newProjects.splice(projectToDeleteIndex, 1);

        if (project.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion) {
          // Business rule : All associated projects in custom workspaces must be deleted after DeFi Gestion project deletion
          newProjects = newProjects.filter(p => {
            // if project has a different siren, we keep it
            if (p.siren !== project.siren) return true;
            // if project has the same siren and is not inside a custom workspace, we DO NOT keep it
            return Object.values(DEFAULT_PROJECTS_SCHEMAS).includes(p.schema_name);
          });
        }

        setConnections(newProjects);
        closeSnackbar(snackbarId);
        showSnackbar(SNACKBAR_ACTIONS.DELETE_PROJECT_SUCCESS);

        return {
          status: 200
        };
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message
      };
    }
  };

  const importFecFile = async (siren, file) => {
    try {
      const filename = await getManualConnectorFilename(file, siren);
      const b64 = await getFileB64(file);
      const formattedFileForApi = b64.split('base64,')[1];

      const url = API_ENDPOINTS.projects.import;
      const {response, responseJson: data} = await _post(url, {
        filename,
        file_b64: formattedFileForApi,
        siren
      });

      return {
        status: 200
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setImportFecFileError(e.message);
      return {
        message: e.message
      };
    }
  };

  const manualRefreshProject = async (project, date = null) => {
    try {
      const url = API_ENDPOINTS.projects.refresh.manual;
      const snackbarId = showSnackbar(
        SNACKBAR_ACTIONS.REFRESH_PROJECT_LOADING,
        {
          severity: 'info',
          autoHide: false
        },
        {projectRefreshing: project.project_name}
      );

      const {response, responseJson: data} = await _post(url, {
        siren: project.siren,
        schema: project.schema_name,
        ...(date !== null && {pivot_date: date})
      });

      closeSnackbar(snackbarId);

      if (response.status !== 200 && response.status !== 201) {
        // showSnackbar(data || data.message, {severity: 'error'});
        return {
          success: false
        };
      }
      showSnackbar(SNACKBAR_ACTIONS.REFRESH_PROJECT_SUCCESS);
      setTimeout(async () => {
        await fetchProjects();
      }, 1000);
      return {
        status: 200,
        message: 'success'
      };
    } catch (e) {
      console.log('Did catch');
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message
      };
    }
  };

  const enableOrDisableProjectAutoRefresh = async project => {
    try {
      const url = API_ENDPOINTS.projects.refresh.enableDisableAuto;
      const {response} = await _post(url, {
        siren: project.siren,
        auto_update: project.auto_update ? 0 : 1,
        schema: project.schema_name
      });

      if (response.status !== 200 && response.status !== 201) {
        return {
          success: false,
          status: response.status
        };
      }
      await fetchProjects();
      return {
        status: 200,
        message: 'success'
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message
      };
    }
  };

  // eslint-disable-next-line complexity
  const getNewConnectionDataIntegrationInProgress = async () => {
    // projects are being fetched, meaning job has succeeded in a previous request. We prevent re-fetching projects
    if (isLoading) {
      return {success: true};
    }

    const JOB_STATUSES = {
      completed: 'Completed',
      pending: 'Pending',
      running: 'Running',
      failed: 'Failed'
    };

    const url = API_ENDPOINTS.projects.newConnectionDataIntegrationStatus;

    try {
      const body = {
        statusQueryGetUri: state.newConnectionDataIntegrationJobUrl
      };

      // We do not want to display a generic error snackbar because this route is meant to be called a lot of times to check the job status
      // And it might timeout or crash at times, so it's not relevant to show an error to the end-user in that case
      const requestOptions = {
        shouldDisplayErrorSnackbar: false
      };

      let isSnackbarAlreadyOpen = snackbars.find(s => s.action === SNACKBAR_ACTIONS.CREATE_CONNECTION_DATA_INTEGRATION_IN_PROGRESS) !== undefined;
      if (!isSnackbarAlreadyOpen) {
        showSnackbar(SNACKBAR_ACTIONS.CREATE_CONNECTION_DATA_INTEGRATION_IN_PROGRESS, {
          severity: 'info',
          autoHide: false
        });
        isSnackbarAlreadyOpen = true;
      }

      const {response, responseJson: data} = await _post(url, body, requestOptions);

      // If API indicated that job has failed, we throw an error and will display a snackbar
      if (response.status === 200 && data.runtimeStatus === JOB_STATUSES.failed) {
        setDataIntegrationJobUrl(null);
        // Here we don't use closeSnackbar because snackbarId might have been generated in previous API call and we do not keep track of it
        // Simplest way is to simply close ALL snackbars related to this job
        setSnackbars(currentSnackbars => currentSnackbars.filter(s => s.action !== SNACKBAR_ACTIONS.CREATE_CONNECTION_DATA_INTEGRATION_IN_PROGRESS));
        showSnackbar(SNACKBAR_ACTIONS.CREATE_CONNECTION_DATA_INTEGRATION_ERROR, {severity: 'error'});
        return {
          success: false
        };
      }

      // If API crashed ( job has not failed, but API does not return either 200 or 202 success statutes)
      // We just return and we do not throw an error
      if (response.status !== 200 && response.status !== 202) {
        return {
          success: false
        };
      }

      if (response.status === 200 && data.runtimeStatus === JOB_STATUSES.completed) {
        setDataIntegrationJobUrl(null);
        // Here we don't use closeSnackbar because snackbarId might have been generated in previous API call and we do not keep track of it
        // Simplest way is to simply close ALL snackbars related to this job
        setSnackbars(currentSnackbars => currentSnackbars.filter(s => s.action !== SNACKBAR_ACTIONS.CREATE_CONNECTION_DATA_INTEGRATION_IN_PROGRESS));
        showSnackbar(data.output);
        await fetchProjects();
      }

      if (response.status === 202 && (data.runtimeStatus === JOB_STATUSES.pending || data.runtimeStatus === JOB_STATUSES.running) && !isSnackbarAlreadyOpen) {
        console.log('here  if (response.status === 202 && (data.runtimeStatus === JOB_STATUSES.pending || data.runtimeStatus === JOB_STATUSES.running) && !isSnackbarAlreadyOpen) {');
        showSnackbar(SNACKBAR_ACTIONS.CREATE_CONNECTION_DATA_INTEGRATION_IN_PROGRESS, {
          severity: 'info',
          autoHide: false
        });
      }
      return {success: true};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message
      };
    }
  };

  const getConnectors = async () => {
    try {
      if (connectors.length > 0) return;

      setIsConnectorsLoading(true);
      const url = API_ENDPOINTS.projects.connectors;
      const {response, responseJson: data} = await _get(url);

      if (response.status === 200) {
        setConnectors(data);
        return {
          success: true,
          status: 200,
          data
        };
      }
      return {
        success: false,
        status: response.status,
        data
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message
      };
    } finally {
      setIsConnectorsLoading(false);
    }
  };

  const projectsWithoutDemoProjects = projects?.filter(p => !REGULAR_EXPRESSIONS.demoSiren.test(p.siren));
  // App is in "guest mode" if user is invited at least on one project (except demo projects)
  const isGuestMode = projectsWithoutDemoProjects?.some(p => !p.is_owner);

  const currentReport = selectedWorkspace?.reports?.find(r => r.report_id === selectedReportTab) || {};

  const runManualProjectDataRefresh = async (project, date = null) => {
    setProjectsSirenCurrentlyRefreshing(sirens => [...sirens, project.siren]);
    await manualRefreshProject(project, date);
    setProjectsSirenCurrentlyRefreshing(sirens => [...sirens.filter(s => s !== project.siren)]);
  };

  const closeProjectMenu = () => {
    setProjectCardMenuAnchorEl(null);
    setSelectedProject(null);
  };

  const updateSummaryInProjects = ({newSummary, theme, siren}) => {
    const projectToUpdateIndex = projects.findIndex(p => p.siren === siren && p.schema_name === DEFAULT_PROJECTS_SCHEMAS.gestion);
    const newProjects = [...projects];
    const updatedProject = newProjects[projectToUpdateIndex];
    newProjects.splice(projectToUpdateIndex, 1);

    const summaryToUpdateIndex = (updatedProject.summaries || []).findIndex(s => s.themes === theme);

    if (summaryToUpdateIndex !== -1) {
      const updatedSummary = updatedProject.summaries[summaryToUpdateIndex];
      updatedProject.summaries.splice(summaryToUpdateIndex, 1);
      updatedSummary.summaries = newSummary;
      updatedProject.summaries.push(updatedSummary);
    }

    const summaryToCreate = {
      summaries: newSummary,
      themes: theme
    };
    if (updatedProject.summaries) {
      updatedProject.summaries.push(summaryToCreate);
    } else {
      updatedProject.summaries = [summaryToCreate];
    }

    newProjects.push(updatedProject);
    setConnections(newProjects);
    reportsContext.setSelectedProject(updatedProject);
  };

  const isLoginToConnectorFormValid = () => {
    let isValid = true;
    connector.credential_fields.forEach(f => {
      if (!loginToConnectorFormData[f.id]) {
        isValid = false;
      }
    });
    return isValid;
  };

  const isProjectCurrentlyUpdating = project => {
    const id = project.siren + project.schema_name;
    const isUpdating = state.updating.find(item => item === id) !== undefined;
    return isUpdating;
  };

  const isProjectCurrentlyDeleting = project => {
    const id = project.siren + project.schema_name;
    const isDeleting = state.deleting.find(item => item === id) !== undefined;
    return isDeleting;
  };

  const memoizedValues = {
    projects,
    fetchProjects,
    updateProject,
    deleteProject,
    addUserToProject,
    updateProjectUsersError,
    setUpdateProjectUsersError,
    deleteUserFromProject,
    updateProjectUsersSuccess,
    setUpdateProjectUsersSuccess,
    createProject,
    createError,
    createProjectApiKeyError,
    setCreateProjectApiKeyError,
    setCreateError,
    isLoading,
    setIsLoading,
    importFecFileError,
    setImportFecFileError,
    importFecFile,
    promoteOrDemoteUser,
    selectedReportTab,
    setSelectedReportTab,
    siren: createProjectSiren,
    setSiren: setCreateProjectSiren,
    isSirenValid,
    handleSirenChange,
    projectCreated,
    setProjectCreated,
    isCegidFormValid,
    setIsCegidFormValid,
    createProjectSirenError,
    setCreateProjectSirenError,
    shouldFetchProjects,
    setShouldFetchProjects,
    isGuestMode,
    username: createProjectUsername,
    setUsername: setCreateProjectUsername,
    password: createProjectPassword,
    setPassword: setCreateProjectPassword,
    apiKey: createProjectApiKey,
    setApiKey: setCreateProjectApiKey,
    manualRefreshProject,
    enableOrDisableProjectAutoRefresh,
    newConnectionDataIntegrationInProgress: state.newConnectionDataIntegrationJobUrl !== null,
    getNewConnectionDataIntegrationInProgress,
    viewType: projectsViewType,
    setViewType: setProjectsViewType,
    deleteProjectModalOpen,
    setDeleteProjectModalOpen,
    updateProjectModalOpen,
    setUpdateProjectModalOpen,
    manageProjectUsersModalOpen,
    setManageProjectUsersModalOpen,
    projectCardMenuAnchorEl,
    setProjectCardMenuAnchorEl,
    selectedProject,
    setSelectedProject,
    projectsSirenCurrentlyRefreshing,
    setProjectsSirenCurrentlyRefreshing,
    currentReport,
    runManualProjectDataRefresh,
    isSubmitting: isProjectCreationSubmitting,
    setIsSubmitting: setIsProjectCreationSubmitting,
    connector,
    setConnector,
    actualizeProjectDataDatePickerOpen,
    setActualizeProjectDataDatePickerOpen,
    closeProjectMenu,
    isActualizeProjectMenuOpen,
    setIsActualizeProjectMenuOpen,
    updateSummaryInProjects,
    getConnectors,
    connectors,
    connectionGroupingType,
    setConnectionGroupingType,
    collapsedCategories,
    setCollapsedCategories,
    loginToConnectorFormData,
    setLoginToConnectorFormData,
    isLoginToConnectorFormValid,
    checkConnectorConnection,
    isLoggingToConnector,
    setIsLoggingToConnector,
    loginToConnector,
    isSuccessfullyLoggedInToConnector,
    setIsSuccessfullyLoggedInToConnector,
    connectorFolders,
    foldersCompanyIdsToConnect,
    setFoldersCompanyIdsToConnect,
    createConnection,
    createProjectModalOpen,
    setCreateProjectModalOpen,
    isConnectorsLoading,
    isRedirectingToTiimeAuthenticationServer,
    setIsRedirectingToTiimeAuthenticationServer,
    maximumNumberOfProjectsReachedModalOpen,
    setMaximumNumberOfProjectsReachedModalOpen,
    isGeneratingTiimeToken,
    setIsGeneratingTiimeToken,
    connectionsCurrentlyDeleting,
    isProjectCurrentlyUpdating,
    isProjectCurrentlyDeleting,
    searchedConnectionTerm,
    setSearchedConnectionTerm
  };

  const useMemoDeps = Object.values(memoizedValues).map(value => value);

  const value = useMemo(() => memoizedValues, useMemoDeps);

  return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
};
ProjectsProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ProjectsProvider;
