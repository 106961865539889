import {DialogContentText, DialogContent, DialogActions} from '@mui/material';

import useWorkspaces from '../../hooks/providers/useWorkspaces';
import Button from '../form/buttons/Button/Button';
import BaseModal from '../modal/BaseModal';

const DeleteWorkspaceOrReportModal = () => {
  const {
    deleteWorkspace,
    deleteReport,
    selectedWorkspaceId,
    setDeleteWorkspaceOrReportModalOpen,
    deleteWorkspaceOrReportModalOpen: open,
    rightClickedReportTab: report,
    rightClickedWorkspace: workspace,
    setAnchorElReportTabsMenu,
    setRightClickedReportTabId,
    closeWorkspaceTabsMenu
  } = useWorkspaces();

  const isReportDeletion = report && !workspace;

  const modalTitle = `Supprimer ${isReportDeletion ? 'le rapport' : "l'espace"}`;
  const confirmationSentence = isReportDeletion ? 'Êtes-vous sûr de vouloir supprimer définitivement ce rapport ?' : 'Êtes-vous sûr de vouloir supprimer définitivement cet espace ?';

  const onClose = () => {
    closeWorkspaceTabsMenu();
    setAnchorElReportTabsMenu(null);
    setRightClickedReportTabId(null);
    setDeleteWorkspaceOrReportModalOpen(false);
  };

  const handleDelete = async () => {
    onClose();
    if (isReportDeletion) {
      await deleteReport(selectedWorkspaceId, report.report_id);
    } else {
      await deleteWorkspace(workspace.workspace_id);
    }
  };

  return (
    <BaseModal title={modalTitle} maxWidth="sm" onClose={onClose} open={open}>
      <DialogContent sx={{py: 3}}>
        <DialogContentText variant="inherit">{confirmationSentence}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center', mb: 2}}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="contained" color="error" onClick={handleDelete}>
          Supprimer
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

export default DeleteWorkspaceOrReportModal;
