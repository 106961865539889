/* eslint-disable react/prop-types */
import {useMemo, useCallback} from 'react';

import {Typography} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {string} from 'prop-types';
import {FixedSizeList as ListWindow} from 'react-window';

import {SORT_TYPES} from '../../../../const';
import useProjects from '../../../../hooks/providers/useProjects';
import Switch from '../../../switch/Switch';

const Row = ({index, style, checked, filteredFolders, handleToggle}) => {
  const {projects, connector} = useProjects();

  const folder = filteredFolders[index];

  if (!folder) return null; // Avoid rendering empty rows

  const folderAlreadyImported = (projects || []).find(p => p.siren === folder.siren && p.connector === connector.id) !== undefined;
  const maximumNumberOfFoldersSelected = checked.length === connector.max_folder_to_load;
  const disabled = folderAlreadyImported || (maximumNumberOfFoldersSelected && !checked.includes(folder.company_id));

  return (
    <ListItem disabled={disabled} sx={{px: 0, py: 0.5, cursor: 'pointer'}} style={style} key={folder.company_id}>
      <Switch
        disabled={disabled}
        edge="end"
        onChange={handleToggle(folder.company_id)}
        checked={checked.includes(folder.company_id) || folderAlreadyImported}
        inputProps={{
          'aria-labelledby': folder.company_id
        }}
      />
      <ListItemText onClick={disabled ? null : handleToggle(folder.company_id)} inset id={folder.company_id} primary={`${folder.company_id} - ${folder.name}`} />
    </ListItem>
  );
};

const ConnectorFoldersList = ({sortType, search}) => {
  const {connectorFolders, foldersCompanyIdsToConnect: checked, setFoldersCompanyIdsToConnect: setChecked} = useProjects();

  const handleToggle = useCallback(
    value => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    },
    [checked, setChecked]
  );

  const getSortedFolders = useCallback(() => {
    if (sortType === SORT_TYPES.NAME) {
      return [...connectorFolders].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    }
    return [...connectorFolders].sort((a, b) => a.company_id - b.company_id);
  }, [connectorFolders, sortType]);

  const sortedFolders = useMemo(() => getSortedFolders(), [getSortedFolders]);
  const filteredFolders = useMemo(() => sortedFolders.filter(f => f.company_id.toString().toLowerCase().includes(search) || f.name.toLowerCase().includes(search)), [sortedFolders, search]);

  if (filteredFolders.length === 0) {
    return (
      <Typography mt={2} fontSize={22} color="text.secondary">
        Aucun dossier ne correspond à vos critères de recherche ...
      </Typography>
    );
  }

  return (
    <List sx={{width: '100%'}}>
      <ListWindow height={300} itemCount={filteredFolders.length} itemSize={40} width="100%">
        {props => <Row {...props} checked={checked} filteredFolders={filteredFolders} handleToggle={handleToggle} />}
      </ListWindow>
    </List>
  );
};

ConnectorFoldersList.propTypes = {
  sortType: string.isRequired,
  search: string.isRequired
};

export default ConnectorFoldersList;
