import {List} from '@mui/material';
import {arrayOf} from 'prop-types';

import {projectShape} from '../../proptypes-shapes';
import Project from './single-project-item/Project';

const ConnectionsList = ({connections}) => (
  <List dense sx={{mt: 2}}>
    {connections.map((project, index) => (
      <Project key={index} project={project} />
    ))}
  </List>
);

ConnectionsList.propTypes = {
  connections: arrayOf(projectShape).isRequired
};

export default ConnectionsList;
