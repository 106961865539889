import {useEffect} from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {IconButton, Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import {bool, func, shape, string} from 'prop-types';

import {useIsModuleEnabled} from '../../../hooks/components/report-modules/useIsModuleEnabled/useIsModuleEnabled';
import useReport from '../../../hooks/providers/useReport';
import SingleReportModule from '../SingleReportModule/SingleReportModule';

const ReportModulesCategory = ({category, isExpanded, setIsExpanded}) => {
  const {searchedModule} = useReport();
  const {isModuleEnabled} = useIsModuleEnabled();

  const {label} = category;

  const theme = useTheme();

  const elementsColor = isExpanded ? 'white ' : 'text.primary';
  const bgColor = isExpanded ? theme.palette.primary.main : 'white';

  const toggleExpanded = () => {
    if (isExpanded) {
      setIsExpanded(null);
    } else {
      setIsExpanded(category.id);
    }
  };

  const categoryContainsMatchingModule = category.modules.some(m => m.name.toLowerCase().includes(searchedModule.toLowerCase()));

  // This hook aims to automatically expanded a category containing a module with searched name in ReportModulesSearchbar
  useEffect(() => {
    if (searchedModule) {
      setIsExpanded(categoryContainsMatchingModule);
    } else {
      setIsExpanded(false);
    }
  }, [searchedModule]);

  const IconComponent = isExpanded ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

  if (searchedModule && !categoryContainsMatchingModule) {
    return null;
  }

  const categoryContainsEnabledModules = category.modules.some(m => isModuleEnabled(m));
  if (!categoryContainsEnabledModules) {
    return null;
  }

  return (
    <Card
      onClick={toggleExpanded}
      variant="outlined"
      sx={{
        cursor: 'pointer',
        boxShadow: ' 0px 0px 4px 0px rgba(0, 0, 0, 0.25)',
        mb: 2,
        background: bgColor,
        borderRadius: 3
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center" py={1} px={1}>
        <Box ml={3} />
        <Typography sx={{color: elementsColor}} fontFamily="InstrumentSansBold" fontSize={16}>
          {label}
        </Typography>
        <IconButton aria-label="ouvrir catégorie module" color="inherit" size="small">
          <IconComponent color={isExpanded ? 'white' : 'text.primary'} />
        </IconButton>

        {isExpanded ? (
          <Card
            onClick={e => e.stopPropagation()}
            variant="outlined"
            sx={{
              width: '90%',
              margin: '0 auto',
              mt: 1.5,
              mb: 1,
              borderRadius: 3
            }}
          >
            {category.modules.map((m, i) => (
              <SingleReportModule key={i} module={m} />
            ))}
          </Card>
        ) : null}
      </Grid>
    </Card>
  );
};

ReportModulesCategory.propTypes = {
  category: shape({
    label: string.isRequired,
    id: string.isRequired
  }).isRequired,
  isExpanded: bool.isRequired,
  setIsExpanded: func.isRequired
};

export default ReportModulesCategory;
