import {useMemo, useState} from 'react';

import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS, EMPLOYEE_TYPES, SNACKBAR_ACTIONS} from '../const';
import ProfileContext from '../contexts/ProfileContext';
import useHttp from '../hooks/misc/useHttp';
import useSnackbar from '../hooks/providers/useSnackbar';
import DefaultAvatar from '../img/default-avatar.png';

const ProfileProvider = ({children}) => {
  const [profile, setProfile] = useState(null);
  const [consents, setConsents] = useState([]);
  const [deleteError, setDeleteError] = useState('');
  const [isConsentsLoading, setIsConsentsLoading] = useState(false);
  const [profileFetched, setProfileFetched] = useState(false);
  const [emailAlreadyTakenError, setEmailAlreadyTakenError] = useState(false);
  const [getHelpButtons, setGetHelpButtons] = useState(null);
  const [isGetHelpLoading, setIsGetHelpLoading] = useState(false);

  const {showSnackbar} = useSnackbar();
  const {_post, _get, _delete} = useHttp();

  const fetchConsents = async (shouldSetConsentLoading = true) => {
    if (isConsentsLoading) return;

    if (shouldSetConsentLoading) {
      setIsConsentsLoading(true);
    }
    try {
      const url = API_ENDPOINTS.consents.get;
      const {response: consentsResponse, responseJson: data} = await _get(url);

      if (consentsResponse?.ok) {
        setConsents(data);
        if (shouldSetConsentLoading) {
          setIsConsentsLoading(false);
        }
        return {
          data,
          success: true
        };
      }

      // eslint-disable-next-line no-console
      console.error({consentsResponse});
      setConsents([]);
      return {
        success: false,
        status: consentsResponse.status
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setConsents([]);
      return {
        error: e,
        success: false
      };
    }
  };

  const saveConsent = async consentType => {
    const url = API_ENDPOINTS.consents.save;
    return _post(url, {
      consent_type: consentType,
      consent_value: true
    });
  };

  const fetchProfile = async () => {
    if (profileFetched) return;
    const {response: profileResponse, responseJson: profileData} = await _get(API_ENDPOINTS.profile.me);

    if (profileResponse?.ok) {
      if (profileData.photo_status_code !== 404) {
        const avatarBlob = await profileData.photo?.blob();
        if (avatarBlob) {
          profileData.avatar = URL.createObjectURL(avatarBlob); // TODO : Handle this once adrian did the backend job
        }
      } else {
        profileData.avatar = DefaultAvatar;
      }
      setProfile(profileData);
      setProfileFetched(true);
    } else {
      // eslint-disable-next-line no-console
      console.error({profileResponse});
      throw Error('Error during profile fecthing');
    }
  };

  const updateProfile = async (updatedProfile, currentPhoneNumber) => {
    const {avatar, email, firstname, lastname, phone, entreprise} = updatedProfile;
    setProfileFetched(false);
    setEmailAlreadyTakenError(false);

    const updateApiCall = (shouldUpdatePhone = true) =>
      _post(API_ENDPOINTS.profile.update, {
        mail: email,
        givenName: firstname,
        surname: lastname,
        companyName: entreprise,
        havephoto_b64: !!avatar,
        photo_b64: avatar,
        businessPhones: shouldUpdatePhone ? [phone] : [currentPhoneNumber]
      });

    try {
      // eslint-disable-next-line prefer-const
      let {response, responseJson: data} = await updateApiCall();

      const emailAlreadyTakenErrorCode = 'ObjectConflict';
      const emailAlreadyTakenErrorTarget = 'proxyAddresses';
      const isEmailAlreadyTakenError = data.error?.details?.find(err => err.code === emailAlreadyTakenErrorCode && err.target === emailAlreadyTakenErrorTarget);

      if (response.status === 400 && isEmailAlreadyTakenError) {
        setEmailAlreadyTakenError(true);
        showSnackbar(SNACKBAR_ACTIONS.SUBSCRIBE_ERROR_EMAIL_ALREADY_TAKEN, {
          severity: 'error',
          autoHide: false
        });
        return {
          success: false
        };
      }

      if (response.status === 403) {
        showSnackbar(SNACKBAR_ACTIONS.UPDATE_PROFILE_PARTIAL, {
          severity: 'warning',
          duration: 5000
        });
        const resp = await updateApiCall(false);
        response = resp?.response;
      }

      if (response.status === 200) {
        showSnackbar(SNACKBAR_ACTIONS.UPDATE_PROFILE_SUCCESS);
        setTimeout(async () => {
          try {
            await fetchProfile();
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
          }
        }, 5000); // timeout 5s to wait for python function to update data, 2s wasn't enough
        return {
          success: true
        };
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('catch', e.message);
      showSnackbar(SNACKBAR_ACTIONS.UPDATE_PROFILE_ERROR, {
        severity: 'error',
        duration: 5000
      });

      return {
        message: e.message
      };
    }
  };

  const deleteProfile = async () => {
    setDeleteError('');
    const url = API_ENDPOINTS.profile.delete;
    try {
      const {response, responseJson: data} = await _delete(url);

      return {
        status: response.status,
        success: response.status === 204
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setDeleteError(e.message);
      return {
        message: e.message
      };
    }
  };

  const getHelp = async () => {
    try {
      const {response, responseJson} = await _get(API_ENDPOINTS.profile.getHelp);
      if (response.status === 200) {
        setGetHelpButtons(responseJson);
        return {
          status: 200
        };
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {
        message: e.message
      };
    }
  };
  const isPartnerPolicy = process.env.REACT_APP_COMPANY_POLICY !== 'drivn';

  const isPartner = profile?.employeeType === EMPLOYEE_TYPES.PARTNER;

  const useMemoDeps = [
    profile,
    setProfile,
    consents,
    setConsents,
    fetchConsents,
    saveConsent,
    fetchProfile,
    updateProfile,
    deleteProfile,
    deleteError,
    setDeleteError,
    isConsentsLoading,
    emailAlreadyTakenError,
    setEmailAlreadyTakenError,
    getHelp,
    getHelpButtons,
    isGetHelpLoading,
    setIsGetHelpLoading,
    isPartner,
    isPartnerPolicy
  ];

  const value = useMemo(
    () => ({
      profile,
      setProfile,
      consents,
      setConsents,
      fetchConsents,
      saveConsent,
      fetchProfile,
      updateProfile,
      deleteProfile,
      deleteError,
      setDeleteError,
      isConsentsLoading,
      emailAlreadyTakenError,
      setEmailAlreadyTakenError,
      getHelp,
      getHelpButtons,
      isGetHelpLoading,
      setIsGetHelpLoading,
      isPartner,
      isPartnerPolicy
    }),
    useMemoDeps
  );

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};
ProfileProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default ProfileProvider;
