import {Grid, Tooltip, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {bool, func, string, node} from 'prop-types';
import styled from 'styled-components';

const Logo = styled.img`
  filter: ${props => (props.disabled ? 'grayscale(0.8)' : 'none')};
  max-width: 40px;
  margin-bottom: 10px;
`;

const ReportExportTypeChoiceButton = ({title, description, logo, onClick, disabled = false}) => {
  return (
    <Grid item xs={6} sx={{maxWidth: 300, textAlign: 'center'}}>
      <Tooltip placement="top" arrow title={disabled ? 'Bientôt disponible' : ''}>
        <Box role="button" px={1} py={2} sx={{border: '1px solid #B2BCD1', borderRadius: 2, cursor: disabled ? 'not-allowed' : 'pointer'}} onClick={disabled ? null : onClick}>
          <Logo disabled={disabled} src={logo} alt="logo export" />
          <Typography color={disabled ? 'text.disabled' : 'inherit'} fontSize={15} mb={1} fontFamily="SoehneBreitKraftig">
            {title}
          </Typography>
          <Typography color={disabled ? 'text.disabled' : 'inherit'} fontSize={13}>
            {description}
          </Typography>
        </Box>
      </Tooltip>
    </Grid>
  );
};

ReportExportTypeChoiceButton.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  disabled: false
};

ReportExportTypeChoiceButton.propTypes = {
  logo: node.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  onClick: func.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  disabled: bool
};

export default ReportExportTypeChoiceButton;
