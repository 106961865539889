import {useEffect} from 'react';

import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';

import {EMBED_REPORT_DEFAULT_SETTINGS} from '../../../const';
import useProfile from '../../../hooks/providers/useProfile';
import useReport from '../../../hooks/providers/useReport';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';
import Button from '../../form/buttons/Button/Button';

const EditReportButton = () => {
  const {profile} = useProfile();
  const {isUserInWorkspace, workspaces} = useWorkspaces();
  const {isReportInEditionMode, setIsSaveReportModalOpen, selectedReport} = useReport();

  const reportWorkspace = workspaces.find(w => (w.reports || []).find(r => r.report_id === selectedReport.config.id) !== undefined);
  const userIsInWorkspace = isUserInWorkspace(profile.id, reportWorkspace);

  const handleClick = async () => {
    setIsSaveReportModalOpen(true);
  };

  useEffect(() => {
    return () => {
      selectedReport.switchMode('view');
      selectedReport.updateSettings(EMBED_REPORT_DEFAULT_SETTINGS);
    };
  }, []);

  if (userIsInWorkspace && isReportInEditionMode) {
    return (
      <Button onClick={handleClick} variant="contained" color="primary" startIcon={isReportInEditionMode ? <EditIcon /> : <EditOffIcon />}>
        Désactiver le mode édition
      </Button>
    );
  }

  return null;
};

export default EditReportButton;
