// This hook aims to fetch stripe products list
// Even if products are not used on this page, it will reduce loading time on /profile and /abonnement pages when user navigates there

import {useEffect} from 'react';

import useAuth from '../../providers/useAuth';
import usePayment from '../../providers/usePayment';

const useFetchProductsList = () => {
  const auth = useAuth();
  const {getProductsList, products, productsLoading} = usePayment();

  useEffect(() => {
    (async () => {
      if (products?.length < 1 && !productsLoading) {
        getProductsList();
      }
    })();
  }, [auth?.user?.tokenAad]);
};

export default useFetchProductsList;
