import useProjects from '../../../hooks/providers/useProjects';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';
import ManageWorkspaceOrReportUsersModal from '../../workspaces/users/ManageWorkspaceOrReportUsersModal';
import CreateCustomWorkspaceConnectionModal from '../create/CreateCustomWorkspaceConnectionModal';
import CreateDatalakeConnectionModal from '../create/CreateDatalakeConnectionModal';
import ManageProjectUsersModal from '../users/ManageProjectUsersModal';
import DeleteProjectModal from './DeleteProjectModal';
import MaximumNumberOfProjectsModal from './MaximumNumberOfProjectsModal';
import UpdateProjectModal from './UpdateProjectModal';

const ConnectionsPageModals = () => {
  const {selectedProject} = useProjects();
  const {rightClickedWorkspace, rightClickedReportTab} = useWorkspaces();
  return (
    <>
      <CreateDatalakeConnectionModal />

      <CreateCustomWorkspaceConnectionModal />

      <MaximumNumberOfProjectsModal />

      {(rightClickedWorkspace || rightClickedReportTab) && <ManageWorkspaceOrReportUsersModal isWorkspaceUsersList={rightClickedWorkspace ?? false} />}

      {selectedProject && (
        <>
          <UpdateProjectModal />

          <ManageProjectUsersModal />

          <DeleteProjectModal />
        </>
      )}
    </>
  );
};

export default ConnectionsPageModals;
