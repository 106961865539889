import {useEffect} from 'react';

import {useLocation} from 'react-router-dom';

import {CONNECTORS} from '../../../const';
import useProjects from '../../providers/useProjects';

// This hook automatically opens project creation modal if location.state.isTiimeProjectCreationModalOpen
// This is useful because when user logs to Tiime oauth, he leaves the site. We want to redirect him to the initial page (folders)
// and keep the modal open + tiime connector selected, to improve UX.
const useOpenConnectionCreationModalAfterTiimeRedirection = () => {
  const {connectors, setConnector, setCreateProjectModalOpen} = useProjects();
  const location = useLocation();

  useEffect(() => {
    const shouldOpen = location.state?.isTiimeProjectCreationModalOpen;
    if (shouldOpen) {
      setCreateProjectModalOpen(true);
      const tiimeConnector = connectors.find(c => c.id === CONNECTORS.tiime);
      if (tiimeConnector) setConnector(tiimeConnector);
    }
  }, [connectors]);
};

export default useOpenConnectionCreationModalAfterTiimeRedirection;
