import {array, arrayOf, bool, number, object, oneOfType, shape, string} from 'prop-types';

const assistantShape = shape({
  id: string.isRequired,
  name: string.isRequired,
  description: string.isRequired,
  role: string.isRequired,
  url_photo: string.isRequired
});

const userShape = shape({
  is_owner: bool,
  user_id: string,
  username: string.isRequired
});

const textPropTypes = shape({
  annotations: arrayOf(string),
  value: string.isRequired
});

const messageShape = shape({
  assistant_id: string,
  content: arrayOf(
    shape({
      type: string.isRequired,
      text: textPropTypes.isRequired
    })
  ).isRequired,
  created_at: number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  file_ids: array,
  id: string,
  // eslint-disable-next-line react/forbid-prop-types
  metadata: object.isRequired,
  object: string.isRequired,
  role: string.isRequired,
  run_id: string,
  thread_id: string.isRequired
});

const summaryShape = shape({
  themes: string.isRequired,
  summaries: string.isRequired
});

const fyShape = shape({
  fy: string.isRequired
});

const projectShape = shape({
  project_name: string.isRequired,
  siren: string.isRequired,
  url_logo: string,
  connector: string.isRequired,
  first_fiscal_month: number.isRequired,
  is_owner: bool.isRequired,
  schema_name: string.isRequired,
  fy_list: arrayOf(string),
  summaries: arrayOf(summaryShape),
  users: arrayOf(userShape).isRequired,
  threshold: number,
  auto_update: bool
});

const reportShape = shape({
  report_name: string.isRequired,
  report_id: string.isRequired,
  is_notice_report: bool,
  isCustomModel: bool.isRequired
});

const workspaceShape = shape({
  workspace_name: string.isRequired,
  workspace_id: string.isRequired
});

const workingDayTranscriptionDataShape = shape({
  CodeClient: oneOfType([string, arrayOf(string)]).isRequired,
  CodeCollaborateur: string.isRequired,
  CodeMission: string.isRequired,
  CodeTache: string.isRequired,
  DateSaisie: string.isRequired,
  Intitulé: string.isRequired,
  Libelle: string.isRequired,
  NbUO: number.isRequired,
  PV1: number.isRequired,
  PV2: number.isRequired,
  PV3: number.isRequired
});

const threadShape = shape({
  thread_id: string.isRequired,
  thread_name: string.isRequired,
  creation_time: number.isRequired
});

const connectorShape = shape({
  category: string.isRequired,
  id: string.isRequired,
  is_active: bool.isRequired,
  is_beta: bool.isRequired,
  is_manual: bool.isRequired,
  is_local: bool,
  max_folder_to_load: number,
  name: string.isRequired,
  open_on_click: string.isRequired,
  url_favicon: string,
  url_help: string,
  url_logo: string,
  credential_fields: arrayOf(shape({}))
});

export {threadShape, assistantShape, userShape, messageShape, projectShape, reportShape, workspaceShape, workingDayTranscriptionDataShape, connectorShape};
