import {useEffect, useState} from 'react';

import DeleteForeverIcon from '@mui/icons-material/DeleteForeverOutlined';
import {DialogActions, DialogContent, Grid, IconButton, TextField} from '@mui/material';

import {REGULAR_EXPRESSIONS, REGULAR_EXPRESSIONS_ERROR_TEXTS, SNACKBAR_ACTIONS} from '../../const';
import useReport from '../../hooks/providers/useReport';
import useSnackbar from '../../hooks/providers/useSnackbar';
import useWorkspaces from '../../hooks/providers/useWorkspaces';
import Button from '../form/buttons/Button/Button';
import BaseModal from '../modal/BaseModal';

// eslint-disable-next-line complexity
const CreateOrUpdateWorkspaceModal = () => {
  const {
    createOrUpdateWorkspaceModalOpen: isOpen,
    isExistingWorkspaceEdition: isUpdate,
    setCreateOrUpdateWorkspaceModalOpen,
    createWorkspace,
    selectedWorkspace,
    setDeleteWorkspaceOrReportModalOpen
  } = useWorkspaces();
  const {loadReports} = useReport();
  const {showSnackbar, closeSnackbar, defaultSnackbarOptions} = useSnackbar();

  const [workspaceName, setWorkspaceName] = useState(selectedWorkspace.workspace_name);

  const modalTitle = isUpdate ? `Paramètres ${selectedWorkspace.workspace_name}` : 'Créer un espace de travail';
  const buttonText = isUpdate ? `Sauvegarder l'espace` : 'Ajouter le nouvel espace';

  const hasNameError = workspaceName !== '' && !REGULAR_EXPRESSIONS.labelWithRangeChars(4, 30).test(workspaceName);
  const canSubmit = workspaceName && !hasNameError && (!isUpdate || (isUpdate && selectedWorkspace.workspace_name !== workspaceName));

  const closeModal = () => {
    setCreateOrUpdateWorkspaceModalOpen(false);
  };

  const onSubmit = async () => {
    closeModal();
    const snackbarId = showSnackbar(SNACKBAR_ACTIONS.CREATE_WORKSPACE_IN_PROGRESS, {
      severity: 'info',
      autoHide: false
    });
    const result = await createWorkspace(workspaceName);

    // We have to wait for the resource to be available back-end side
    // eslint-disable-next-line no-promise-executor-return
    await new Promise(resolve => setTimeout(resolve, 4000));

    await loadReports();
    closeSnackbar(snackbarId);

    if (result.success) {
      showSnackbar(SNACKBAR_ACTIONS.CREATE_WORKSPACE_SUCCESS, defaultSnackbarOptions, {
        workspaceName
      });
    }
  };

  useEffect(() => {
    if (isOpen && isUpdate) {
      setWorkspaceName(selectedWorkspace.workspace_name);
    } else {
      setWorkspaceName('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (isUpdate) {
      setWorkspaceName(selectedWorkspace.workspace_name);
    }
  }, [isUpdate]);
  return (
    <BaseModal onClose={closeModal} open={isOpen} title={modalTitle}>
      <DialogContent sx={{p: 3}}>
        <Grid container flexDirection="column" alignItems="center">
          <TextField
            autoFocus
            required
            value={workspaceName}
            onChange={event => {
              setWorkspaceName(event.target.value);
            }}
            label="Nom"
            placeholder="Saisir le nom de cet espace de travail"
            color="primary"
            fullWidth
            error={hasNameError}
            helperText={hasNameError ? REGULAR_EXPRESSIONS_ERROR_TEXTS.labelWithRangeChars(4, 30) : ''}
          />
        </Grid>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center', mb: 2}}>
        <Button disabled={!canSubmit} onClick={onSubmit} fullWidth variant="contained" color="secondary" size="large">
          {buttonText}
        </Button>
      </DialogActions>

      {isUpdate && (
        <Grid container justifyContent="center">
          <IconButton
            onClick={() => setDeleteWorkspaceOrReportModalOpen(true)}
            edge="end"
            aria-label="menu"
            color="error"
            sx={{
              width: 50,
              height: 50,
              border: `2px solid`,
              mb: 3
            }}
          >
            <DeleteForeverIcon sx={{fontSize: 25}} />
          </IconButton>
        </Grid>
      )}
    </BaseModal>
  );
};

export default CreateOrUpdateWorkspaceModal;
