import {FileDownload as FileDownloadIcon} from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import {bool} from 'prop-types';
import {useLocation} from 'react-router-dom';

import useReport from '../../hooks/providers/useReport';
import HeaderStyledIconButton from './HeaderStyledIconButton/HeaderStyledIconButton';

const HeaderExportReportButton = ({isMobile = false}) => {
  const {setIsReportExportTypeChoiceModalOpen, isReportInEditionMode, allReportVisuals, isReportLoaded} = useReport();

  const location = useLocation();

  const handleIconClick = async () => {
    setIsReportExportTypeChoiceModalOpen(true);
  };

  const isReportPage = location?.pathname?.startsWith('/reports');
  if (!isReportPage || isReportInEditionMode || allReportVisuals.length === 0 || !isReportLoaded) {
    return null;
  }

  if (isMobile) {
    return (
      <MenuItem onClick={handleIconClick}>
        <ListItemIcon>
          <FileDownloadIcon fontSize="small" color="primary" />
        </ListItemIcon>
        <ListItemText>Exporter</ListItemText>
      </MenuItem>
    );
  }

  return (
    <HeaderStyledIconButton onClick={handleIconClick}>
      <FileDownloadIcon />
    </HeaderStyledIconButton>
  );
};

HeaderExportReportButton.defaultProps = {
  isMobile: false
};

HeaderExportReportButton.propTypes = {
  isMobile: bool
};

export default HeaderExportReportButton;
