import {Grid} from '@mui/material';

import {BREAKPOINTS} from '../../../../const';
import useBreakpoint from '../../../../hooks/dom/useBreakpoint';
import useProjects from '../../../../hooks/providers/useProjects';
import useWorkspaces from '../../../../hooks/providers/useWorkspaces';
import SearchBar from '../../../form/SearchBar/SearchBar';
import ConnectionsCounter from '../ConnectionsCounter/ConnectionsCounter';
import ConnectionsGroupingTypeToggler from '../ConnectionsGroupingTypeToggler/ConnectionsGroupingTypeToggler';
import ConnectionsViewTypeToggler from '../ConnectionsViewTypeToggler/ConnectionsViewTypeToggler';
import CreateConnectionButton from '../CreateConnectionButton/CreateConnectionButton';

// eslint-disable-next-line complexity
const ConnectionsToolbar = () => {
  const {searchedConnectionTerm: searchedProject, setSearchedConnectionTerm: setSearchedProject} = useProjects();

  const {isGuestMode} = useProjects();
  const {isDefaultWorkspace} = useWorkspaces();

  const breakpoint = useBreakpoint();

  return (
    <Grid flexWrap="wrap" container mt={2} spacing={breakpoint === BREAKPOINTS.xs ? 0.5 : 2} alignItems="center" justifyContent="center">
      <Grid item xs minWidth={breakpoint === BREAKPOINTS.xs ? '100%' : 300} sx={{maxWidth: {xs: 330, sm: '100%'}, width: '100%'}}>
        <SearchBar placeholder="Recherchez un dossier, un utilisateur, une connexion ..." value={searchedProject} setValue={setSearchedProject} />
      </Grid>
      <Grid item sm="auto" md="auto">
        <Grid container alignItems="center" mt={breakpoint === BREAKPOINTS.xs ? 1.5 : 0}>
          {isDefaultWorkspace ? (
            <Grid item>
              <ConnectionsGroupingTypeToggler />
            </Grid>
          ) : null}

          <Grid item sx={{display: {xs: 'none', sm: 'flex'}, mr: 1}}>
            <ConnectionsViewTypeToggler />
          </Grid>

          <Grid item>
            <Grid container flexDirection="column" alignItems="center">
              <CreateConnectionButton />
            </Grid>
          </Grid>

          <Grid item ml={{xs: 0.5, sm: 2}}>
            {!isGuestMode && <ConnectionsCounter />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConnectionsToolbar;
