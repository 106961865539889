import {Typography, useTheme} from '@mui/material';
import {shape, string} from 'prop-types';

import {EMBED_EDITABLE_REPORT_SETTINGS, REPORT_MODULES_IDS} from '../../../const';
import {useIsModuleEnabled} from '../../../hooks/components/report-modules/useIsModuleEnabled/useIsModuleEnabled';
import useProjectCrudModal from '../../../hooks/dom/useProjectCrudModal';
import useProjectsCustomMappings from '../../../hooks/providers/useProjectsCustomMappings';
import useReport from '../../../hooks/providers/useReport';
import useReportSummary from '../../../hooks/providers/useReportSummary';
import useWorkspaces from '../../../hooks/providers/useWorkspaces';

const SingleReportModule = ({module}) => {
  const {
    reports,
    selectedReport,
    setIsCreateOrUpdateBudgetFormOpen,
    setIsSpreadsheetModalOpen,
    setIsCreateBudgetForm,
    setIsDeleteBudgetDialogOpen,
    selectedProject: project,
    setAccountantDocumentRequestModalOpen,
    setIsReportInEditionMode,
    setIsReportModulesSidebarOpen,
    setNoProjectsSelectedBackdropOpen,
    alreadyTakenBudgetNames: budgets,
    setSelectedBudget,
    setIsReportExportTypeChoiceModalOpen,
    reportSelectedFoldersSirens
  } = useReport();
  const {setReportSummaryModalOpen} = useReportSummary();
  const {setCustomizeMappingModalOpen, resetProjectCustomMappings} = useProjectsCustomMappings();
  const {setRightClickedReportTab: setReportToDelete, setDeleteWorkspaceOrReportModalOpen: setDeleteReportModalOpen} = useWorkspaces();
  const currentReport = reports.find(r => r.report_id === selectedReport?.config?.id);
  const {isModuleEnabled} = useIsModuleEnabled();

  const {openModal: openCustomMappingsModal} = useProjectCrudModal({
    setModalOpen: setCustomizeMappingModalOpen,
    project
  });

  const isModuleEnabledForCurrentReport = isModuleEnabled(module);

  // eslint-disable-next-line complexity
  const handleClick = async () => {
    if (!isModuleEnabledForCurrentReport) return;

    const hasExactlyOneSirenSelected = reportSelectedFoldersSirens.length === 1;
    if (module.requiresSirenSelected && !hasExactlyOneSirenSelected) {
      setNoProjectsSelectedBackdropOpen(true);
      return;
    }

    switch (module.id) {
      case REPORT_MODULES_IDS.CREATE_BUDGET:
        setIsCreateOrUpdateBudgetFormOpen(true);
        setIsCreateBudgetForm(true);
        break;
      case REPORT_MODULES_IDS.EDIT_BUDGET:
        if (budgets.length === 1) {
          setSelectedBudget(budgets[0]);
        }
        setIsSpreadsheetModalOpen(true);
        break;
      case REPORT_MODULES_IDS.DELETE_BUDGET:
        setIsDeleteBudgetDialogOpen(true);
        break;
      case REPORT_MODULES_IDS.CUSTOMIZE_MAPPINGS:
        openCustomMappingsModal();
        break;
      case REPORT_MODULES_IDS.RESET_CUSTOMIZE_MAPPINGS:
        setIsReportModulesSidebarOpen(false);
        await resetProjectCustomMappings(project.siren);

        break;
      case REPORT_MODULES_IDS.UPDATE_REPORT_SUMMARY:
        setReportSummaryModalOpen(true);
        break;
      case REPORT_MODULES_IDS.SHARE_DOCUMENT_WITH_ACCOUNTANT:
        setAccountantDocumentRequestModalOpen(true);
        break;
      case REPORT_MODULES_IDS.DELETE_REPORT:
        setDeleteReportModalOpen(true);
        setReportToDelete(currentReport);
        // TODO maybe we'll have to redirect or somethign when report is deleted ?
        break;
      case REPORT_MODULES_IDS.EDIT_REPORT:
        await selectedReport.switchMode('edit');
        await selectedReport.updateSettings(EMBED_EDITABLE_REPORT_SETTINGS);
        setIsReportInEditionMode(true);
        setIsReportModulesSidebarOpen(false);
        break;
      case REPORT_MODULES_IDS.EXPORT_REPORT:
        setIsReportExportTypeChoiceModalOpen(true);

        break;
      default:
        // eslint-disable-next-line no-console
        console.log('Unknown module id :', module.id);
        break;
    }
  };
  const theme = useTheme();

  if (isModuleEnabledForCurrentReport) {
    return (
      <Typography
        onClick={handleClick}
        py={1.5}
        textAlign="center"
        fontSize={13}
        color="text.primary"
        sx={{
          borderTop: '1px solid #DEDEDE',
          '&:hover': {
            color: theme.palette.secondary.main
          },
          cursor: 'pointer'
        }}
      >
        {module.name}
      </Typography>
    );
  }

  return null;
};

SingleReportModule.propTypes = {
  module: shape({
    name: string.isRequired,
    id: string.isRequired
  }).isRequired
};

export default SingleReportModule;
