import {DialogContent, Grid} from '@mui/material';

import useReportExporter from '../../../../hooks/components/report-export/useReportExporter';
import useReport from '../../../../hooks/providers/useReport';
import Button from '../../../form/buttons/Button/Button';
import BaseModal from '../../../modal/BaseModal';
import Spinner from '../../../spinner/Spinner';
import ExportButtons from '../ExportButtons/ExportButtons';
import ProgressBarSection from '../ProgressBarsSection/ProgressBarsSection';

const ReportExportTypeChoiceModal = () => {
  const {isReportExportTypeChoiceModalOpen: isOpen, setIsReportExportTypeChoiceModalOpen: setIsOpen} = useReport();
  const {state, handleExport, cancelExport} = useReportExporter();

  return (
    <BaseModal sx={{backgroundColor: state.isExporting ? 'rgba(0, 0, 0, 0.9)' : 'inherit'}} canCloseOnBackdropClick={!state.isExporting} onClose={() => setIsOpen(false)} open={isOpen} title="Export">
      <DialogContent sx={{p: 3}}>
        {state.isExporting ? (
          <>
            <Spinner isLoading text={state.exportingStep === 'EXPORTING_VISUALS' ? 'Export des visuels' : 'Génération du fichier'} />
            {state.exportingStep === 'EXPORTING_VISUALS' && state.shouldDisplayVisualsExportingProgress && (
              <>
                <ProgressBarSection
                  numberOfPagesExported={state.numberOfPagesExported}
                  totalNumberOfPages={state.totalNumberOfPages}
                  numberOfVisualsExported={state.numberOfVisualsExported}
                  totalNumberOfVisualsInPage={state.totalNumberOfVisualsInPage}
                />
                <Grid container justifyContent="center" mt={3}>
                  <Button size="large" variant="contained" color="error" onClick={cancelExport}>
                    Annuler l'export
                  </Button>
                </Grid>
              </>
            )}
          </>
        ) : (
          <ExportButtons handleClick={handleExport} />
        )}
      </DialogContent>
    </BaseModal>
  );
};

export default ReportExportTypeChoiceModal;
