import {REPORT_MODULES_IDS} from '../../../../const';
import useProfile from '../../../providers/useProfile';
import useReport from '../../../providers/useReport';
import useWorkspaces from '../../../providers/useWorkspaces';

export function useIsModuleEnabled() {
  const {reports = [], selectedReport, isDesktopLayout, isReportEditable, alreadyTakenBudgetNames: budgets = [], spreadsheetDataLoaded} = useReport();

  const {profile} = useProfile();
  const {workspaces = [], isUserInWorkspace} = useWorkspaces();

  const selectedReportId = selectedReport?.config?.id;
  const currentReport = reports.find(r => r.report_id === selectedReportId) || {};

  const isEditReportEnabled = () => {
    const reportWorkspace = workspaces.find(w => (w.reports || []).some(r => r.report_id === selectedReportId));
    return isReportEditable && isUserInWorkspace(profile?.id, reportWorkspace) && isDesktopLayout;
  };

  const isModuleEnabled = module => {
    switch (module.id) {
      case REPORT_MODULES_IDS.EDIT_REPORT:
        return isEditReportEnabled();
      case REPORT_MODULES_IDS.EXPORT_REPORT:
        return true;
      case REPORT_MODULES_IDS.EDIT_BUDGET:
      case REPORT_MODULES_IDS.DELETE_BUDGET:
        return budgets.length > 0 && spreadsheetDataLoaded;
      default:
        return currentReport?.[module.backendId] ?? false;
    }
  };

  return {isModuleEnabled};
}
