import {Grid, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import {func} from 'prop-types';

import {REPORT_EXPORT_FEATURES} from '../../../../const';
import ReportExportTypeChoiceButton from '../ReportExportTypeChoiceButton/ReportExportTypeChoiceButton';

const ExportButtons = ({handleClick}) =>
  REPORT_EXPORT_FEATURES.map((f, index) => (
    <Box key={f.id}>
      <Typography color="text.secondary" mb={1} mt={index > 0 ? 2 : 0} fontSize={16} fontFamily="SoehneBreitKraftig">
        {f.categoryTitle}
      </Typography>
      <Grid container spacing={2} flexWrap="nowrap">
        {f.options.map(opt => (
          <ReportExportTypeChoiceButton key={opt.title} logo={f.logo} disabled={opt.disabled} title={opt.title} description={opt.description} onClick={() => handleClick(f.id)} />
        ))}
      </Grid>
    </Box>
  ));

ExportButtons.defaultProps = {
  handleClick: null
};

ExportButtons.propTypes = {
  handleClick: func
};

export default ExportButtons;
