import {useEffect, useState} from 'react';

import {DialogActions, DialogContent} from '@mui/material';
import {useLocation} from 'react-router-dom';

import useProjects from '../../../hooks/providers/useProjects';
import useProjectsCustomMappings from '../../../hooks/providers/useProjectsCustomMappings';
import AutoScroll from '../../autoscroll/AutoScroll';
import Button from '../../form/buttons/Button/Button';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';
import CustomMappingsSpreadsheet from './CustomMappingsSpreadsheet';
import ToggleVisibleRowsSwitch from './ToggleVisibleRowsSwitch/ToggleVisibleRowsSwitch';

// eslint-disable-next-line complexity
const CustomizeMappingModal = () => {
  const {closeProjectMenu, selectedProject: project} = useProjects();
  const {
    customizeMappingModalOpen: open,
    setCustomizeMappingModalOpen: setOpen,
    projectCustomMappings,
    getProjectCustomMappings,
    resetProjectCustomMappings,
    updateProjectCustomMappings
  } = useProjectsCustomMappings();

  const [isResetting, setIsResetting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hiddenRowsVisible, setHiddenRowsVisible] = useState(false);

  const {siren} = project || {};

  const onClose = () => {
    closeProjectMenu();
    setOpen(false);
  };

  const handleResetMappings = () => {
    (async () => {
      setIsResetting(true);
      await resetProjectCustomMappings(siren);
      setIsResetting(false);
    })();
  };

  const handleSubmit = async () => {
    onClose();
    const updatedMappings = projectCustomMappings.get(siren);
    updateProjectCustomMappings(siren, updatedMappings);
  };

  useEffect(() => {
    (async () => {
      if (open && !projectCustomMappings?.has(siren)) {
        setIsLoading(true);
        await getProjectCustomMappings(siren);
        setIsLoading(false);
      }
    })();
  }, [open, siren]);

  const toggleHiddenRowsVisible = () => {
    setHiddenRowsVisible(!hiddenRowsVisible);
  };

  const location = useLocation();
  const isReportPage = location.pathname.startsWith('/reports');

  return (
    <BaseModal
      shouldMatchEmbedReportWidth={isReportPage}
      overridesPaperProps={{margin: 0}}
      hasFullScreenButton
      hasCloseIcon
      maxWidth={false}
      title="Modifier l'affectation des comptes"
      onClose={onClose}
      open={open}
    >
      <DialogContent sx={{pl: 1}}>
        <ToggleVisibleRowsSwitch hiddenRowsVisible={hiddenRowsVisible} toggleHiddenRowsVisible={toggleHiddenRowsVisible} />
        <AutoScroll>
          <Spinner isLoading={isLoading} text="Chargement des données" />
          {projectCustomMappings?.has(siren) && !isLoading ? <CustomMappingsSpreadsheet hiddenRowsVisible={hiddenRowsVisible} /> : null}
        </AutoScroll>
      </DialogContent>

      <DialogActions
        sx={{
          mb: 2,
          display: 'flex',
          width: '100%',
          justifyContent: 'center'
        }}
      >
        <Button disabled={isResetting || isLoading} variant="contained" color="success" onClick={handleSubmit}>
          Mettre à jour
        </Button>
        <Button disabled={isResetting || isLoading} variant="contained" color="secondary" onClick={handleResetMappings}>
          Réinitialiser
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

export default CustomizeMappingModal;
