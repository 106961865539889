import {DialogActions, DialogContent, Typography} from '@mui/material';
import {bool, func, string} from 'prop-types';
import styled from 'styled-components';

import useReportSummary from '../../../hooks/providers/useReportSummary';
import Button from '../../form/buttons/Button/Button';
import BaseModal from '../../modal/BaseModal';

const BoldText = styled.span`
  font-family: InstrumentSansBold;
`;

const DeletePromptConfirmModal = ({isOpen, onClose, model}) => {
  const {deletePromptModel} = useReportSummary();

  const handleSubmit = async () => {
    onClose();
    return deletePromptModel(model);
  };

  return (
    <BaseModal onClose={onClose} title="Supprimer le modèle" open={isOpen}>
      <DialogContent sx={{p: 3}}>
        <Typography>
          Vous êtes sur le point de supprimer le modèle <BoldText>" {model} "</BoldText>.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="contained" color="error" onClick={handleSubmit}>
          Supprimer le modèle
        </Button>
      </DialogActions>
    </BaseModal>
  );
};

DeletePromptConfirmModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  model: string.isRequired
};

export default DeletePromptConfirmModal;
