import {useEffect, useState} from 'react';

import {Box, DialogContent, DialogActions, FormControlLabel, Typography} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';

import {REGULAR_EXPRESSIONS} from '../../../const';
import useReport from '../../../hooks/providers/useReport';
import Button from '../../form/buttons/Button/Button';
import FormSelect from '../../form/MuiSelect/MuiSelect';
import BaseModal from '../../modal/BaseModal';
import Spinner from '../../spinner/Spinner';
import BudgetsSelect from './BudgetsSelect';

// eslint-disable-next-line complexity
const DeleteBudgetDialog = () => {
  const {
    deleteBudget,
    isDeleteBudgetDialogOpen: isOpen,
    setIsDeleteBudgetDialogOpen,
    setSpreadsheetMenuOpen,
    setIsSpreadsheetModalOpen,
    selectedBudget: budgetName,
    setSelectedBudget,
    selectedBudgetYears: budgetYears,
    selectedProject: project
  } = useReport();

  const [shouldDeleteWholeBudget, setShouldDeleteWholeBudget] = useState(false);
  const [year, setYear] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const budgetRegex = REGULAR_EXPRESSIONS.labelWithMinimumChars(4);
  const hasLabelError = budgetName !== '' && !budgetRegex.test(budgetName);
  const canSubmit = budgetName && (year || shouldDeleteWholeBudget || budgetYears.length === 1) && !hasLabelError;

  // This hook aims to reset state on modal closing
  // Without this hook, the component might keep the state shouldDeleteWholeBudget=true and not display the years select
  useEffect(() => {
    if (!isOpen) {
      setYear('');
      setShouldDeleteWholeBudget(false);
      setSelectedBudget(null);
    }
  }, [isOpen]);

  const hasOnlyOneBudgetYear = budgetYears.length === 1;

  return (
    <BaseModal onClose={() => setIsDeleteBudgetDialogOpen(false)} open={isOpen} title="Supprimer un budget">
      <DialogContent>
        <BudgetsSelect onSelect={setSelectedBudget} />
        {budgetName ? (
          <Box my={2}>
            {!shouldDeleteWholeBudget && budgetYears.length > 1 && (
              <FormSelect
                disabled={isSubmitting}
                sx={{width: '100%', mb: 2}}
                label="Sélectionner une année à supprimer"
                labelId="year-label"
                value={year}
                onChange={e => {
                  setYear(e.target.value);
                }}
                items={budgetYears.map(y => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ))}
              />
            )}

            {!hasOnlyOneBudgetYear ? (
              <FormControlLabel
                disabled={isSubmitting}
                control={<Checkbox onChange={ev => setShouldDeleteWholeBudget(ev.target.checked)} />}
                label={<Typography variant="p">Supprimer le budget</Typography>}
              />
            ) : null}
          </Box>
        ) : null}
      </DialogContent>
      {isSubmitting && <Spinner text="Suppression du budget" isLoading />}

      {budgetName ? (
        <DialogActions sx={{justifyContent: 'center'}}>
          <Button
            disabled={isSubmitting || !canSubmit}
            variant="contained"
            color="primary"
            onClick={async () => {
              setIsSubmitting(true);
              const res = await deleteBudget({
                budgetName: budgetName.trim(),
                budgetYear: shouldDeleteWholeBudget || hasOnlyOneBudgetYear ? null : year,
                siren: project?.siren
              });
              setIsSubmitting(false);
              if (res.status === 200) {
                setIsDeleteBudgetDialogOpen(false);
                setSpreadsheetMenuOpen(false);
                if (shouldDeleteWholeBudget) {
                  setIsSpreadsheetModalOpen(false);
                  setSelectedBudget(null);
                }
              }
            }}
          >
            Supprimer le budget
          </Button>
        </DialogActions>
      ) : null}
    </BaseModal>
  );
};

export default DeleteBudgetDialog;
