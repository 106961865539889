import {useMemo, useState} from 'react';

import {oneOfType, node, func} from 'prop-types';

import {API_ENDPOINTS, SNACKBAR_ACTIONS} from '../const';
import TranscriptContext from '../contexts/TranscriptContext';
import useAudioRecorder from '../hooks/misc/useAudioRecorder';
import useHttp from '../hooks/misc/useHttp';
import useProjects from '../hooks/providers/useProjects';
import useReport from '../hooks/providers/useReport';
import useSnackbar from '../hooks/providers/useSnackbar';
import useWorkspaces from '../hooks/providers/useWorkspaces';

const TranscriptProvider = ({children}) => {
  const [isTranscriptWorkingDayModalOpen, setIsTranscriptWorkingDayModalOpen] = useState(false);
  const [isRecordingDone, setIsRecordingDone] = useState(false);
  const [transcriptionData, setTranscriptionData] = useState([]);

  const {_post} = useHttp();

  const {selectedWorkspace} = useWorkspaces();
  const {selectedReportTab} = useProjects();
  const {showSnackbar, setSnackbars} = useSnackbar();
  const {selectedReport, selectedReportId} = useReport();

  const {
    startRecording,
    estimatedFileSize,
    stopRecording,
    base64Data,
    setBase64Data,
    isRecording,
    userHasDeclinedMicrophonePermission,
    audioExceedsMaximumSize,
    audioWillSoonExceedMaximumSize,
    setMediaStream
  } = useAudioRecorder();

  const formatApiTranscriptionData = (data, startId) => {
    return data.map((entry, index) => {
      // eslint-disable-next-line no-use-before-define
      const clients = getReportClientsForSpreadsheet();

      return {
        id: startId + index,
        date: entry.DateSaisie,
        folderOptions: Array.isArray(entry.CodeClient) ? entry.CodeClient : clients,
        folder: Array.isArray(entry.CodeClient) ? entry.CodeClient[0] : entry.CodeClient,
        action: entry.Libelle || '',
        mission: entry.CodeMission || '',
        task: entry.CodeTache || '',
        duration: entry.NbUO || 0,
        // These data are not used by the front-end, so we do not change keys
        PV1: entry.PV1,
        PV2: entry.PV2,
        PV3: entry.PV3,
        Intitulé: entry.Intitulé,
        CodeCollaborateur: entry.CodeCollaborateur
      };
    });
  };

  const formatTranscriptionToApiCompliantFormat = data => {
    return data.map(entry => {
      return {
        DateSaisie: entry.date,
        CodeClient: entry.folder,
        CodeTache: entry.task,
        CodeMission: entry.mission,
        CodeCollaborateur: entry.CodeCollaborateur,
        Libelle: entry.action,
        NbUO: entry.duration,
        PV1: entry.PV1,
        PV2: entry.PV2,
        PV3: entry.PV3,
        Intitulé: entry.Intitulé
      };
    });
  };

  const transcriptWorkingDay = async base64Audio => {
    const filename = 'transcription.mpeg';

    const url = API_ENDPOINTS.reports.transcriptWorkingDay;
    try {
      const {response, responseJson: data} = await _post(url, {
        filename,
        base64_audio: base64Audio
      });

      if (response.status === 200) {
        return {data, success: true, status: response.status};
      }

      if (response.status === 400) {
        showSnackbar(SNACKBAR_ACTIONS.INVALID_TRANSCRIPTION, {severity: 'error', duration: 5000});
        return {data, success: false};
      }
      return {success: false};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    }
  };

  const saveWorkingDayTranscription = async transcription => {
    const url = API_ENDPOINTS.reports.sendTranscription;
    try {
      const formattedData = formatTranscriptionToApiCompliantFormat(transcription);
      const {response, responseJson: data} = await _post(url, {
        transcription: formattedData
      });

      if (response.status === 200) {
        showSnackbar(data);
        if (selectedReport.config.id) {
          await selectedReport.refresh();
        }
        return {success: true, status: response.status};
      }
      return {success: false, status: response.status};
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return {success: false};
    }
  };

  // TODO DRY, refactor all this shit
  const getReportClientsForSpreadsheet = () => {
    const reportId = selectedReportId || selectedReportTab;
    const report = selectedWorkspace.reports?.find(r => r.report_id === reportId);

    return report.timesheet_parameters?.collab_folders.map(folder => `${folder.Code} - ${folder.NomInt}`);
  };

  const getReportMissionsForSpreadsheet = () => {
    const reportId = selectedReportId || selectedReportTab;
    const report = selectedWorkspace.reports?.find(r => r.report_id === reportId);
    const tasks = report?.timesheet_parameters?.tasks;

    const allMissions = tasks?.map(task => task.Mission);
    const distinctMissions = [...new Set(allMissions)];

    return distinctMissions;
  };

  const getReportTasksCodesForSpreadsheet = mission => {
    const reportId = selectedReportId || selectedReportTab;
    const report = selectedWorkspace.reports?.find(r => r.report_id === reportId);
    const tasks = report?.timesheet_parameters?.tasks;

    const filteredTasks = mission ? tasks.filter(t => t.Mission === mission) : tasks;

    const allTasks = filteredTasks?.map(task => ({
      label: `${task.Tache} (${task['Intitulé Tache']})`,
      value: task.Tache
    }));

    return allTasks;
  };

  const handleStartRecording = () => {
    setIsRecordingDone(false);
    startRecording();
  };

  const handleStopRecording = async (shouldSaveRecord = true) => {
    stopRecording(shouldSaveRecord);
    setSnackbars(currentSnackbars => currentSnackbars.filter(s => s.action !== SNACKBAR_ACTIONS.TRANSCRIPTION_AUDIO_FILE_WILL_SOON_EXCEED_MAXIMUM_SIZE_WARNING));
  };

  const useMemoDeps = [
    isTranscriptWorkingDayModalOpen,
    setIsTranscriptWorkingDayModalOpen,
    transcriptWorkingDay,
    handleStopRecording,
    handleStartRecording,
    isRecordingDone,
    setIsRecordingDone,
    transcriptionData,
    setTranscriptionData,
    base64Data,
    setBase64Data,
    isRecording,
    userHasDeclinedMicrophonePermission,
    audioExceedsMaximumSize,
    audioWillSoonExceedMaximumSize,
    estimatedFileSize,
    setMediaStream,
    saveWorkingDayTranscription
  ];
  const value = useMemo(
    () => ({
      isTranscriptWorkingDayModalOpen,
      setIsTranscriptWorkingDayModalOpen,
      transcriptWorkingDay,
      handleStopRecording,
      handleStartRecording,
      isRecordingDone,
      setIsRecordingDone,
      transcriptionData,
      setTranscriptionData,
      base64Data,
      setBase64Data,
      isRecording,
      userHasDeclinedMicrophonePermission,
      audioExceedsMaximumSize,
      audioWillSoonExceedMaximumSize,
      estimatedFileSize,
      setMediaStream,
      formatApiTranscriptionData,
      saveWorkingDayTranscription,
      getReportClientsForSpreadsheet,
      getReportTasksCodesForSpreadsheet,
      getReportMissionsForSpreadsheet
    }),
    useMemoDeps
  );

  return <TranscriptContext.Provider value={value}>{children}</TranscriptContext.Provider>;
};

TranscriptProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default TranscriptProvider;
