import {useState, useMemo} from 'react';

import {oneOfType, node, func} from 'prop-types';
import {v4 as uuidv4} from 'uuid';

import SnackbarContext from '../contexts/SnackbarContext';
import {getSnackbarMessage} from '../utils';

const defaultSnackbarOptions = {
  severity: 'success',
  duration: 4000,
  hasSpinner: false
};

const SnackbarProvider = ({children}) => {
  const [snackbars, setSnackbars] = useState([]);

  const showSnackbar = (action, options = defaultSnackbarOptions, data = {}) => {
    const snackbarMessage = getSnackbarMessage(action, data);
    const snackbarId = uuidv4();

    const newSnackbar = {
      id: snackbarId,
      message: snackbarMessage,
      severity: options.severity,
      duration: options.autoHide === false ? null : options.duration,
      hasSpinner: options.hasSpinner,
      isOpen: true,
      action
    };

    setSnackbars(currentSnackbars => {
      const snackbarWithSameIdIndex = currentSnackbars.findIndex(s => s.id === newSnackbar.id);
      if (snackbarWithSameIdIndex !== -1) {
        const newSnackbars = [...currentSnackbars];
        newSnackbars[snackbarWithSameIdIndex] = newSnackbar;
        return newSnackbars;
      }
      return [...currentSnackbars, newSnackbar];
    });

    return snackbarId;
  };

  const closeSnackbar = snackbarIdToClose => {
    setSnackbars(currentSnackbars => {
      const snackbarsAfterClosing = [...currentSnackbars];
      const snackbarIndex = snackbarsAfterClosing.findIndex(s => s.id === snackbarIdToClose);
      const snackbarToClose = {...snackbarsAfterClosing[snackbarIndex]};
      snackbarToClose.isOpen = false;
      snackbarsAfterClosing[snackbarIndex] = snackbarToClose;
      return snackbarsAfterClosing;
    });
  };

  /*  const closeSnackbar = snackbarIdToClose => {
                                      setSnackbars(currentSnackbars => [...currentSnackbars].filter(s => s.id !== snackbarIdToClose));
                                    }; */

  const value = useMemo(
    () => ({
      snackbars,
      setSnackbars,
      showSnackbar,
      closeSnackbar,
      defaultSnackbarOptions
    }),
    [snackbars, setSnackbars, showSnackbar, closeSnackbar, defaultSnackbarOptions]
  );

  return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>;
};

SnackbarProvider.propTypes = {
  children: oneOfType([node, func]).isRequired
};

export default SnackbarProvider;
